<template>
    <div style="width: 900px; text-align: left">
        <div class="star item-space">
            <span class="add-video-input-title title">视频名称：</span>
            <label class="add-video-input-select">
                <Input v-model="name" :readonly="true"/>
            </label>
        </div>
        <div class="star item-space">
            <span class="add-video-input-title title">编导：</span>
            <label class="add-video-input-select">
                <Input v-model="director" :readonly="true"/>
            </label>
        </div>
        <div class="star item-space">
            <span class="add-video-input-title title">达人：</span>
            <label class="add-video-input-select">
                <Input v-model="star" :readonly="true"/>
            </label>
        </div>
        <div class="add-video-input item-space desc">
            <span class="add-video-input-title title">视频说明：</span>
            <Input class="add-video-input-select" type="textarea" :rows="10" v-model="description"  :readonly="true"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoInfoBase",
        props:['name', 'director', 'star', 'description'],
    }
</script>

<style scoped src="../css/order.css">

</style>