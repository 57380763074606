<template>
    <div>
        <page-header
                :title-txt="'短视频'"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
            <label>
                <Input search enter-button="查询" v-model="searchTxt" placeholder="搜索视频名称、编导、达人" @on-search="search(1)"
                       style="width: 300px"/>
            </label>
            <div style="display: flex">
                <Button type="primary" style="width: 140px" @click="addVideo">新建短视频</Button>
                <Select style="text-align: left; margin-left: 20px; width: 200px" v-model="orderTypeId"
                        placeholder="需求单" @on-change="addOrder">
                    <Option v-for="item in orders" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
            </div>
        </div>
        <div style="margin-top: 20px">
            <Table border :loading=isLoading :columns="tbColumn" :data="mTbData">
                <template slot-scope="{index}" slot="index">
                    <span>{{index+1}}</span>
                </template>
                <template slot-scope="{row}" slot="action">
                    <Button type="info" size="small" style="margin-right: 5px" @click="videoInfo(row.id)">详情</Button>
                    <Button type="error" size="small" @click="delVideo(row.id, row.name)">删除</Button>
                </template>
            </Table>
            <div class="page">
                <Page v-if="mTotalPage >= 20" :total="mTotalPage" :current.sync="currentPage" @on-change="search"/>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";

    export default {
        name: "Videos",
        components: {PageHeader},
        props: [],
        data() {
            return {
                searchTxt: '',
                orderTypeId: 0,
                currentPage: 1,
                mTotalPage: 0,
                mTbData: [],
                isLoading: false,
                tbColumn: [
                    {
                        title: "序号",
                        slot: "index",
                        align: "center"
                    },
                    {
                        title: "视频名称",
                        key: "name",
                        align: "center"
                    },
                    {
                        title: "编导",
                        key: "director",
                        align: "center"
                    },
                    {
                        title: "达人",
                        key: "star",
                        align: "center"
                    },
                    {
                        title: "创建时间",
                        key: "createTime",
                        align: "center"
                    },
                    {
                        title: "上线时间",
                        key: "onlineTime",
                        align: "center"
                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center",
                        width: 150,
                    },
                ],
                orders: [
                    {
                        id: 1,
                        name: '《脚本需求单》'
                    },
                    {
                        id: 2,
                        name: '《编曲需求单》'
                    },
                    {
                        id: 3,
                        name: '《录音需求单》'
                    },
                    {
                        id: 4,
                        name: '《混音需求单》'
                    },
                    {
                        id: 5,
                        name: '《拍摄需求单》'
                    },
                ]
            }
        },
        created() {
            this.search(1);
        },
        methods: {
            addVideo() {
                this.$router.push({path: 'addVideo'});
            },
            addOrder() {
                if (!this.orderTypeId) {
                    return
                }
                this.$router.push({
                    path: 'addOrder', query: {
                        type: this.orderTypeId
                    }
                });
            },
            delVideo(vid, name) {
                if (!vid || !name) {
                    return 0;
                }
                this.$Modal.confirm({
                    title: `确认删除短视频《${name}》？`, onOk: () => {
                        this.isLoading = true;
                        new Promise((reso, rej) => {
                            g_func.delVideo(vid, (res) => {
                                this.isLoading = false;
                                if (res.code === 0) {
                                    this.mTbData = this.mTbData.filter((v) => {
                                        return v.id !== vid;
                                    });
                                    reso();
                                } else {
                                    rej(res.err);
                                }
                            });
                        }).then(() => {
                            g_func.doOk(this);
                        }, (err) => {
                            this.$Message.error(err)
                        })
                    }
                });
            },
            videoInfo(vid) {
                this.$router.push({path: 'videoInfo', query: {videoId: vid}});
            },
            search(n) {
                this.currentPage = n;
                let page = n <= 0 ? 0 : n - 1;
                let pageCount = 10;
                this.isLoading = true;
                g_func.getVideos(this.searchTxt, page * pageCount, pageCount, (res) => {
                    this.isLoading = false;
                    if (!g_func.checkResCode(this, res, `查询短视频失败`)) {
                        return 0;
                    }
                    this.mTbData = [];
                    this.mTotalPage = Math.ceil(res.msg.total / pageCount) * 10;
                    res.msg.videos.forEach((v) => {
                        this.mTbData.push({
                            id: v.id,
                            name: v.name,
                            director: v.director,
                            star: v.star,
                            createTime: v.createTime.split(' ')[0],
                            onlineTime: v.onlineTime.split(' ')[0],
                        });
                    });
                    this.$Message['info']({
                        background: true,
                        content: `查询完成: 共${res.msg.total ? res.msg.total : 0}个结果`,
                        duration: 2,
                    });
                });
            },
        }
    }
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>