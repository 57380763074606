<template>
    <div>
        <page-header
                :title-txt="mTitle"
        >
        </page-header>
        <div class="center">
            <div>
                <div class="child-title">视频信息</div>
                <video-info-base
                        :name="mVideo.name"
                        :director="mVideo.director"
                        :star="mVideo.star"
                        :description="mVideo.description"
                >
                </video-info-base>

                <div class="child-title">需求单</div>
                <div v-if="mOrder.type === 1">
                    <div class="add-video-input item-space desc">
                        <span class="add-video-input-title title">脚本说明：</span>
                        <Input class="add-video-input-select" type="textarea" :rows="10" v-model="mOrder.description"
                               :readonly="true"/>
                    </div>
                </div>
                <div v-if="mOrder.type === 2">
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">制作人：</span>
                        <Input class="add-video-input-select" v-model="mOrder.producer" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">风格：</span>
                        <Input class="add-video-input-select" v-model="mOrder.style" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">参考曲目：</span>
                        <Input class="add-video-input-select" v-model="mOrder.references" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space desc">
                        <span class="add-video-input-title title">编曲说明：</span>
                        <label class="add-video-input-select">
                            <Input type="textarea" :rows="10" v-model="mOrder.description" :readonly="true"/>
                        </label>
                    </div>
                </div>
                <div v-if="mOrder.type === 3">
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">录音师：</span>
                        <Input class="add-video-input-select" v-model="mOrder.recorder" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">录音棚：</span>
                        <Input class="add-video-input-select" v-model="mOrder.studio" :readonly="true"/>
                    </div>
                </div>
                <div v-if="mOrder.type === 4">
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">风格：</span>
                        <Input class="add-video-input-select" v-model="mOrder.style" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">混音师：</span>
                        <Input class="add-video-input-select" v-model="mOrder.mixer" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space desc">
                        <span class="add-video-input-title title">混音说明：</span>
                        <Input class="add-video-input-select" type="textarea" :rows="10" v-model="mOrder.description"
                               :readonly="true"/>
                    </div>
                </div>
                <div v-if="mOrder.type === 5">
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">时间：</span>
                        <Input class="add-video-input-select" v-model="mOrder.time" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">地点：</span>
                        <Input class="add-video-input-select" v-model="mOrder.place" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space desc">
                        <span class="add-video-input-title title">拍摄说明：</span>
                        <Input class="add-video-input-select" type="textarea" :rows="10" v-model="mOrder.description"
                               :readonly="true"/>
                    </div>
                </div>
                <div class="item-space" v-if="mShowAction && mOrder.type <=5 && mOrder.type >= 1">
                    <upload-item :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                                 :btn-txt="`上传${mButtonTxt1}`"
                                 :file-path="mOrder.path"
                                 v-if="mOrder.stat === 2"
                                 @ok="upload($event, arguments)">
                    </upload-item>
                    <Button :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                            v-if="mOrder.stat === 2"
                            @click="finish">确认完成
                    </Button>
                </div>
                <div class="add-video-input item-space desc" v-if="mOrder.stat === 3 && mOrder.path">
                    <span class="add-video-input-title title">需求单文件：</span>
                    <a class="add-video-input-select" style="text-align: left" @click="openUrl">{{mOrder.path}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoInfoBase from "./VideoInfoBase";
    import {g_func, g_user} from "../source";
    import PageHeader from "./PageHeader";
    import UploadItem from "./UploadItem";

    export default {
        name: "OrderInfo",
        components: {UploadItem, PageHeader, VideoInfoBase},
        props: ['orderId'],
        data() {
            return {
                mTitle: '',
                mShowAction: false,
                mIsLoading: false,
                mButtonTxt1: '',
                mVideo: {
                    id: 0,
                    name: '',
                    director: '',
                    star: '',
                    desc: '',
                },
                mOrder: {
                    id: this.orderId,
                    type: 0,
                    description: '',
                    style: '',
                    references: '',
                    producer: '',
                    director: '',
                    star: '',
                    recorder: '',
                    studio: '',
                    mixer: '',
                    time: '',
                    place: '',
                    stat: 0,
                    finishUserId: '',
                    path: '',
                }
            }
        },
        created() {
            if (!this.orderId) {
                g_func.errParam(this, '短视频或需求单不存在或已删除');
                this.$router.go(-1);
                return;
            }
            this.getOrder();
        },
        methods: {
            getVideo() {
                if (!this.mVideo.id) {
                    g_func.errParam(this, '短视频或需求单不存在或已删除');
                    return
                }
                g_func.getVideoById(this.mVideo.id, (res) => {
                    if (!g_func.checkResCode(this, res, `获取短视频信息失败`, () => {
                        this.$router.go(-1);
                    })) {
                        return 0;
                    }
                    this.mVideo.name = res.msg.title;
                    this.mVideo.desc = res.msg.description;
                    res.msg.stars.forEach((v) => {
                        if (this.mVideo.star !== '') {
                            this.mVideo.star += '，'
                        }
                        this.mVideo.star += v.s.name;
                        if (this.mVideo.director.indexOf(v.d.name) === -1) {
                            if (this.mVideo.director !== '') {
                                this.mVideo.director += '，'
                            }
                            this.mVideo.director += v.d.name;
                        }
                    });
                });
            },
            getOrder() {
                g_func.getOrderById(this.orderId, res => {
                    if (!g_func.checkResCode(this, res, '获取需求单详情失败')) {
                        return;
                    }
                    let order = res.msg.order;
                    if (!order) {
                        return;
                    }
                    console.log(res);
                    let info = JSON.parse(order.description);
                    this.mOrder.id = order.id;
                    this.mVideo.id = order.videoId;
                    this.mOrder.type = order.tp;
                    this.mTitle = g_func.getScript(order.tp).name;
                    this.mOrder.description = info.description;
                    this.mOrder.style = info.style;
                    this.mOrder.references = info.references;
                    this.mOrder.finishUserId = order.finishUser.id;
                    this.mOrder.producer = order.finishUser.name;
                    this.mOrder.director = order.finishUser.name;
                    this.mOrder.star = info.star;
                    this.mOrder.stat = order.stat;
                    this.mOrder.recorder = order.finishUser.name;
                    this.mOrder.mixer = order.finishUser.name;
                    this.mOrder.time = info.time;
                    this.mOrder.place = info.place;
                    this.mOrder.path = order.filePath;
                    this.mButtonTxt1 = function (type) {
                        switch (type) {
                            case 1:
                                return '脚本文件';
                            case 2:
                                return '编曲文件';
                            case 3:
                                return '录音文件';
                            case 4:
                                return '混音文件';
                            case 5:
                                return '视频塑材文件';
                            default:
                                return '';
                        }
                    }(this.mOrder.type);
                    this.mShowAction = g_user.isAdmin() || g_user.getId() === this.mOrder.finishUserId;

                    this.getVideo();

                    if (this.mOrder.type === 3) {
                        g_func.getOrderStudioByOrderId(this.mOrder.id, res => {
                            if (!g_func.checkResCode(this, res, `获取短视频信息失败`, () => {
                                this.$router.go(-1);
                            })) {
                                return 0;
                            }
                            res.msg.data.forEach(v => {
                                if (this.mOrder.studio !== '') {
                                    this.mOrder.studio += '；'
                                }
                                this.mOrder.studio += `${v.name} [${g_func.getDatetime(new Date(v.beginU * 1000))} ~ ${g_func.getDatetime(new Date(v.endU * 1000))}]`
                            });
                        });
                    }
                });
            },
            finish() {
                if (!this.orderId) {
                    return
                }
                this.mIsLoading = true;
                g_func.finishOrder(this.orderId, res => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `获取短视频信息失败`)) {
                        return
                    }
                    this.$Message.info('完成需求单成功');
                    this.$router.go(-1);
                })
            },
            upload(p) {
                g_func.setOrderFile(this.orderId, p, res => {
                    if (!g_func.checkResCode(this, res, `上传失败`)) {
                        return 0;
                    }
                    this.$Message.info('上传成功');
                });
            },
            openUrl() {
                if (!this.mOrder.path) {
                    return
                }
                window.open(this.mOrder.path);
            },
        },
    }
</script>

<style scoped src="../css/order.css">

</style>
<style scoped>
    .child-title {
        font-size: 25px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
    }
</style>