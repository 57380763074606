<template>
    <div class="center" style="align-items: start">
        <div class="add-video-input item-space" v-if="!hidePath">
            <span class="title" v-if="mPath">{{btnTxt.replace(/上传/, '')}}：</span>
            <a class="add-video-input-select" @click="openUrl">{{mPath}}</a>
        </div>


        <div class="loading">
            <Upload ref="upload" action="https://upload.yuzijiang.tv/upload/upload" :data="{}"
                    :before-upload="getUploadToken" :show-upload-list="false" :on-success="uploadSuccess"
                    :on-progress="uploading" :on-error="uploadError" :disabled="loading">
                <Button class="btn-do" type="primary" id="btn" :loading="loading">{{btnTxt}}</Button>
            </Upload>
        </div>
    </div>
</template>

<script>
    import {g_func} from "../source";

    export default {
        name: "UploadItem",
        props: ['btnTxt', 'filePath', 'hidePath', 'loading'],
        data() {
            return {
                mPath: this.filePath,
            }
        },
        created() {
        },
        methods: {
            getUploadToken(file) {
                g_func.getUploadFileToken(res => {
                    if (!g_func.checkResCode(this, res, `获取上传权限失败`)) {
                        return 0;
                    }
                    if (!res.msg.uploadToken) {
                        g_func.error(this, '上传失败', '权限错误');
                        return;
                    }
                    this.setLoadingWidth(0);
                    this.$set(this.$refs.upload.data, 'token', res.msg.uploadToken);
                    this.$refs['upload'].post(file);
                });
                return false;
            },
            uploadSuccess(res) {
                this.mPath = res.msg;
                this.setBtnTxt(`重新${this.btnTxt}`);
                this.$emit('ok', this.mPath);
            },
            uploading(event) {
                this.setLoadingWidth(event.percent);
            },
            uploadError(error) {
                g_func.error(this, '上传文件错误', error);
            },
            setLoadingWidth(percent) {
                let btn = document.getElementById('btn');
                btn.innerText = '';
                btn.setAttribute('style', `width:${percent / 100 * 900}px`);
            },
            openUrl() {
                if (!this.mPath) {
                    return
                }
                window.open(this.mPath);
            },
            setBtnTxt(txt) {
                document.getElementById('btn').innerText = txt;
            },
        },
        watch: {
            filePath(val) {
                console.log(val);
                this.mPath = val;
                if (this.mPath) {
                    this.setBtnTxt(`重新${this.btnTxt}`);
                }
            },
        }
    }
</script>

<style scoped src="../css/order.css">
</style>

<style scoped>

    .loading {
        width: 900px;
        border: solid 1px dodgerblue;
        border-radius: 4px;
        text-align: left;
    }
</style>