<template>
    <div>
        <page-header
                :title-txt="mTitle"
                v-if="!hideTitle"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: center">
            <div style="width: 900px; text-align: left">
                <video-info-base
                        :name="mVideoName"
                        :director="mDirector"
                        :star="mStar"
                        :description="mDescription"
                >
                </video-info-base>
                <div class="star item-space btn-border">
                    <Button class="btn" :type="btnTypeScript" @click="btnOrderClick(1, btnTypeScript, btnScriptId)">脚本
                    </Button>
                    <Button class="btn" :type="btnTypeMusic" @click="btnOrderClick(2, btnTypeMusic, btnMusicId)">编曲
                    </Button>
                    <Button class="btn" :type="btnTypeRecord" @click="btnOrderClick(3, btnTypeRecord, btnRecordId)">录音
                    </Button>
                    <Button class="btn" :type="btnTypeMix" @click="btnOrderClick(4, btnTypeMix, btnMixId)">混音</Button>
                    <Button class="btn" :type="btnTypeShoot" @click="btnOrderClick(5, btnTypeShoot, btnShootId)">拍摄
                    </Button>
                </div>
                <div class="add-video-input item-space desc" v-if="btnOnline">
                    <span class="add-video-input-title title">终版视频：</span>
                    <a v-if="mVideo.path" class="add-video-input-select" style="text-align: left" @click="openUrl">{{mVideo.path}}</a>
                    <span v-if="!mVideo.path" class="add-video-input-select" style="text-align: left">无</span>
                </div>
                <div class="star item-space">
                    <Button type="default" class="btn-do" v-if="btnOnline" :disabled="true">已上线</Button>
                </div>
                <div v-if="!btnOnline && showOnlineBtn">
                    <upload-item class="star item-space" v-if="!btnOnline" :btn-txt="'上传终版视频'"
                                 :file-path="mVideo.path"
                                 @ok="uploadOk($event, arguments)"
                    >
                    </upload-item>
                    <div class="star item-space">
                        <Button type="primary" class="btn-do" @click="setVideoOnline">
                            确认上线
                        </Button>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column">
                <div style="flex: 1"></div>
                <div class="stat-block">
                    <span>需求单状态说明</span>
                    <Button class="btn-show" type="default">无</Button>
                    <Button class="btn-show" type="success">已完成</Button>
                    <Button class="btn-show" type="error">未完成</Button>
                    <Button class="btn-show" type="warning">待审核</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func, g_user} from "../source";
    import VideoInfoBase from "./VideoInfoBase";
    import UploadItem from "./UploadItem";

    export default {
        name: "VideoInfo",
        components: {UploadItem, VideoInfoBase, PageHeader},
        props: ['videoId', 'hideTitle'],
        data() {
            return {
                mTitle: "短视频信息",
                mVideoName: '',
                mDirector: '',
                mStar: '',
                mDescription: '',
                btnTypeScript: 'default',
                btnTypeMusic: 'default',
                btnTypeRecord: 'default',
                btnTypeMix: 'default',
                btnTypeShoot: 'default',
                btnScriptId: 0,
                btnMusicId: 0,
                btnRecordId: 0,
                btnMixId: 0,
                btnShootId: 0,
                btnOnline: true,
                mOnlineTxt: '确认上线',
                mVid: this.videoId,
                mVideo: {},
                showOnlineBtn: false,
            }
        },
        created() {
            if (!this.mVid) {
                g_func.errParam(this, `短视频不存在或已删除`);
                this.$router.push({name: 'Videos'});
                return
            }
            g_func.getVideoById(this.mVid, (res) => {
                if (!g_func.checkResCode(this, res, `获取短视频详情失败`)) {
                    this.$router.push({name: 'Videos'});
                    return 0;
                }
                this.mTitle = res.msg.title;
                this.mVideoName = res.msg.title;
                this.mDescription = res.msg.description;
                this.mStar = '';
                this.mDirector = '';
                this.btnOnline = res.msg.stat === 2;
                this.mVideo = res.msg;
                res.msg.stars.forEach((v) => {
                    if (this.mStar !== '') {
                        this.mStar += '，'
                    }
                    this.mStar += v.s.name;
                });
                res.msg.stars.forEach((v) => {
                    if (this.mDirector.indexOf(v.d.name) === -1) {
                        if (this.mDirector !== '') {
                            this.mDirector += '，'
                        }
                        this.mDirector += v.d.name;
                    }
                });

                let showBtnOnline = true;
                res.msg.orderStat.forEach((v) => {
                    let btnStat = function (stat) {
                        switch (stat) {
                            case 1:
                                return 'warning';
                            case 2:
                                return 'error';
                            case 3:
                                return 'success';
                        }
                        return 'default'
                    }(v.stat);

                    if (v.stat === 1 || v.stat === 2) {
                        showBtnOnline = false;
                    }

                    switch (v.type) {
                        case 1:
                            this.btnTypeScript = btnStat;
                            this.btnScriptId = v.id;
                            break;
                        case 2:
                            this.btnTypeMusic = btnStat;
                            this.btnMusicId = v.id;
                            break;
                        case 3:
                            this.btnTypeRecord = btnStat;
                            this.btnRecordId = v.id;
                            break;
                        case 4:
                            this.btnTypeMix = btnStat;
                            this.btnMixId = v.id;
                            break;
                        case 5:
                            this.btnTypeShoot = btnStat;
                            this.btnShootId = v.id;
                            break;
                    }
                });
                this.showOnlineBtn = showBtnOnline;
                this.mVideo.stars.forEach(v => {
                    if (v.d.id === g_user.getId()) {
                        this.showOnlineBtn = true;
                    }
                });

                if (!g_user.isAdmin() && this.mDirector.indexOf(g_user.getNick()) < 0 && this.mVideo.creator.id != g_user.getId()) {
                    this.showOnlineBtn = false
                }
            });
        },
        methods: {
            btnOrderClick(n, stat, orderId) {
                if (stat.trim() !== 'default') {
                    if (!orderId) {
                        return
                    }
                    this.$router.push({
                        name: 'OrderInfo', query: {
                            orderId: orderId,
                        }
                    });
                } else {
                    if (this.btnOnline) {
                        return;
                    }
                    if (!g_user.isAdmin() && this.mDirector.indexOf(g_user.getNick()) < 0 && this.mVideo.creator.id != g_user.getId()) {
                        return;
                    }
                    this.$router.push({
                        path: 'addOrder', query: {
                            type: n,
                            videoId: this.mVid,
                        }
                    });
                }
            },
            uploadOk(path) {
                g_func.setVideoFile(this.videoId, path, res => {
                    if (!g_func.checkResCode(this, res, `上传失败`)) {
                        return 0;
                    }
                    this.$Message.info('上传成功');
                });
            },
            setVideoOnline() {
                g_func.setVideoOnline(this.videoId, res => {
                    if (!g_func.checkResCode(this, res, `上线短视频失败`)) {
                        return 0;
                    }
                    this.$Message.info('上线短视频成功');
                    this.btnOnline = true;
                })
            },
            openUrl() {
                if (!this.mVideo.path) {
                    return
                }
                window.open(this.mVideo.path);
            },
        },
    }
</script>


<style scoped src="../css/order.css">
</style>

<style scoped>

    .btn-border {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .btn {
        width: 100%;
        height: 80px;
        margin: 5px;
        border-radius: 10px;
    }

    .btn-show {
        margin: 10px;
        cursor: default;
    }

    .btn-do {
        width: 100%;
        height: 40px;
    }

    .stat-block {
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 200px;
        margin: 0 40px 60px;
        border: dashed 1px #515afe;
        border-radius: 10px;
    }
</style>