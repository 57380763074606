<template>
    <div>
        <page-header
                :btn1-txt="'添加员工'"
                :btn2-txt="'添加达人'"
                :title-txt="'人员管理'"
                @btn1Click="addUser"
                @btn2Click="addStar"
        >
        </page-header>
        <div>
            <div>
                <Button type="default" class="btn" @click="users(0)">全部</Button>
                <Button type="default" class="btn" @click="users(2)">项目经理</Button>
                <Button type="default" class="btn" @click="users(1)">编导</Button>
                <Button type="default" class="btn" @click="users(3)">制作人</Button>
                <Button type="default" class="btn" @click="users(4)">录音师</Button>
                <Button type="default" class="btn" @click="users(5)">混音师</Button>
                <Button type="default" class="btn" @click="users(6)">商务</Button>
                <Button type="default" class="btn" @click="users(7)">运营</Button>
                <Button type="default" class="btn" @click="stars()">达人</Button>
            </div>
            <div style="margin-top: 20px;">
                <Table border :loading=mLoading :columns="!mBStar ? mTbColUser: mTbColStar" :data="mTbData">
                    <template slot-scope="{index}" slot="inA">
                        <span>{{index+1}}</span>
                    </template>
                    <template slot-scope="{row}" slot="action">
                        <div style="display: flex; align-items: center; flex-direction: row; justify-content: center">
                            <Button type="text" style="margin: 0; padding: 0" size="large" @click="edit(row)">编辑
                            </Button>
                            <span style="padding: 1px 2px 0 2px">/</span>
                            <Button type="text" style="margin: 0; padding: 0" size="large" @click="del(row)">删除</Button>
                        </div>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>


<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";

    export default {
        name: "Users",
        components: {PageHeader},
        props: ['cid'],
        data() {
            return {
                mTbColUser: [
                    {
                        title: "序号",
                        slot: "inA",
                        align: "center"
                    },
                    {
                        title: "姓名",
                        key: "n",
                        align: "center"
                    },
                    {
                        title: "角色",
                        key: "c",
                        align: "center"
                    },
                    {
                        title: "添加时间",
                        key: "ct",
                        align: "center"
                    },
                    {
                        title: "审核编曲需求",
                        key: "mcStr",
                        align: "center"
                    },
                    {
                        title: "项目经理",
                        key: "pmStr",
                        align: "center"
                    },
                    {
                        title: "管理员",
                        key: "aStr",
                        align: "center"
                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center"
                    },
                ],
                mTbColStar: [
                    {
                        title: "序号",
                        slot: "inA",
                        align: "center"
                    },
                    {
                        title: "达人",
                        key: "n",
                        align: "center"
                    },
                    {
                        title: "所属编导",
                        key: "dir",
                        align: "center"
                    },
                    {
                        title: "添加时间",
                        key: "ct",
                        align: "center"
                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center"
                    },
                ],
                mTbData: [],
                mBStar: false,
                mLoading: false,
            }
        },
        created() {
            if (this.cid === 8) {
                this.stars();
            } else {
                this.users(this.cid ? this.cid : 0);
            }
        },
        methods: {
            addUser() {
                this.$router.push({path: 'addUser'})
            },
            addStar() {
                this.$router.push({path: 'addStar'})
            },
            edit(row) {
                if (this.mBStar) {
                    row.director = row.d.id;
                    this.$router.push({path: 'addStar', query: {id: row.id}})
                } else {
                    this.$router.push({path: 'addUser', query: {id: row.id}})
                }
            },
            del(row) {
                this.$Modal.confirm({
                    title: `确认删除《${row.n}》？`,
                    content: `删除前请确认该人员已完成所有工作。如果是编导请确认没有达人属于该编导`,
                    onOk: () => {
                        g_func.deleteUser(row.id, res => {
                            if (res.code) {
                                this.$Message.error('删除失败')
                                return;
                            }
                            if (!g_func.checkResCode(this, res, '删除用户失败')) {
                                return;
                            }
                            g_func.doOk(this);
                            this.mTbData = this.mTbData.filter(v => {return v.id !== row.id})
                        })
                    }
                })
            },
            users(t) {
                this.mBStar = false;
                this.mLoading = true;
                g_func.getUsers(t, (res) => {
                    this.mLoading = false;
                    if (!g_func.checkResCode(this, res, '获取用户列表失败')) {
                        return;
                    }
                    this.mTbData = res.msg.users;
                    for (let i in this.mTbData) {
                        this.mTbData[i].aStr = this.mTbData[i].a === 1 ? '是' : '否';
                        this.mTbData[i].mcStr = this.mTbData[i].mc === 1 ? '是' : '否';
                        this.mTbData[i].pmStr = this.mTbData[i].pm === 1 ? '是' : '否';
                        this.mTbData[i].ct = this.mTbData[i].ct.split(' ')[0];
                    }
                })
            },
            stars() {
                this.mBStar = true;
                this.mLoading = true;
                g_func.getStars((res) => {
                    this.mLoading = false;
                    if (!g_func.checkResCode(this, res, '获取达人列表失败')) {
                        return;
                    }
                    this.mTbData = [];
                    res.msg.stars.forEach((v) => {
                        this.mTbData.push({
                            id: v.s.id,
                            n: v.s.n,
                            p: v.s.p,
                            dir: v.d.n,
                            ct: v.s.ct.split(' ')[0],
                            d: {
                                id: v.d.id,
                                name: v.d.n,
                            }
                        });
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .btn {
        margin: 5px;
        width: 7vw;
        min-width: 60px;
    }
</style>