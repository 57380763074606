import Vue from 'vue'
import VueRouter from 'vue-router'
import MyWork from "../components/MyWork";
import PlatformData from "../components/PlatformData";
import ProjectAction from "../components/ProjectAction";
import Projects from "../components/Projects";
import SourceStorage from "../components/SourceStorage";
import Studio from "../components/Studio";
import Users from "../components/Users";
import Videos from "../components/Videos";
import AddUser from "../components/AddUser";
import AddStar from "../components/AddStar";
import AddVideo from "../components/AddVideo";
import VideoInfo from "../components/VideoInfo";
import AddOrder from "../components/AddOrder";
import AddProject from "../components/AddProject";
import MusicOrderConfirm from "../components/MusicOrderConfirm";
import OrderInfo from "../components/OrderInfo";
import Resource from "../components/Resource";
import ResourceIdea from "../components/ResourceIdea";
import AddIdea from "../components/AddIdea";


Vue.use(VueRouter);

const routes = [
    {
        path: '/myWork',
        name: 'MyWork',
        component: MyWork,
    },
    {
        path: '/platformData',
        name: 'PlatformData',
        component: PlatformData,
    },
    {
        path: '/projectAction',
        name: 'ProjectAction',
        component: ProjectAction,
        props: (route) => ({projectId: route.query.projectId}),
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
    },
    {
        path: '/sourceStorage',
        name: 'SourceStorage',
        component: SourceStorage,
    },
    {
        path: '/studio',
        name: 'Studio',
        component: Studio,
        props: (route) => ({showBtn: route.query.showBtn}),
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        props: true,
    },
    {
        path: '/videos',
        name: 'Videos',
        component: Videos,
    },
    {
        path: '/addUser',
        name: 'AddUser',
        component: AddUser,
        props: (route) => ({id: route.query.id}),
    },
    {
        path: '/addStar',
        name: 'AddStar',
        component: AddStar,
        props: (route) => ({id: route.query.id}),
    },
    {
        path: '/addVideo',
        name: 'AddVideo',
        component: AddVideo,
        props: (route) => ({projectId: route.query.projectId}),
    },
    {
        path: '/videoInfo',
        name: 'VideoInfo',
        component: VideoInfo,
        props: (route) => ({videoId: route.query.videoId, hideTitle: route.query.hideTitle}),
    },
    {
        path: '/addOrder',
        name: 'AddOrder',
        component: AddOrder,
        props: (route) => ({videoId: route.query.videoId, type: parseInt(route.query.type)}),
    },
    {
        path: '/addProject',
        name: 'AddProject',
        component: AddProject,
    },
    {
        path: '/musicOrderConfirm',
        name: 'MusicOrderConfirm',
        component: MusicOrderConfirm,
        props: (route) => ({orderId: route.query.orderId}),
    },
    {
        path: '/orderInfo',
        name: 'OrderInfo',
        component: OrderInfo,
        props: (route) => ({orderId: route.query.orderId}),
    },
    {
        path: '/resource',
        name: 'Resource',
        component: Resource,
        props: (route) => ({type: parseInt(route.query.type)}),
    },
    {
        path: '/resourceIdea',
        name: 'ResourceData',
        component: ResourceIdea,
    },
    {
        path: '/addIdea',
        name: 'AddIdea',
        component: AddIdea,
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};