<template>
    <div>
        <page-header
                :title-txt="mTitle"
        >
        </page-header>
        <Table style="margin-top: 20px" border :loading=mIsLoading :columns="mTbTitle" :data="mTbData">
            <template slot-scope="{row}" slot="play">
                <a v-if="showPlay(row.path)" @click="play(row)">{{row.name}}</a>
                <span v-if="!showPlay(row.path)">{{row.name}}</span>
            </template>
            <template slot-scope="{row}" slot="download">
                <a :href="row.path" target="_blank">下载</a>
            </template>
        </Table>
        <div class="page">
            <Page v-if="mTotalPage >= 20" :total="mTotalPage" :current.sync="mCurrentPage" @on-change="search"/>
        </div>
        <Modal
                v-model="mModalPlay"
                :closable="false"
                :footer-hide="true"
                @on-cancel="cancel"
        >
            <video style="width: 100%; height: 100%"
                   :src="mPlayPath"
                   controls="controls" autoplay loop/>
        </Modal>
    </div>
</template>

<script>
    import {g_func} from "../source";
    import PageHeader from "./PageHeader";

    export default {
        name: "Resource",
        components: {PageHeader},
        props: ['type'],
        data() {
            return {
                mTitle: '',
                mCurrentPage: 1,
                mTotalPage: 0,
                mTbTitle: [
                    {
                        title: '序号',
                        key: 'index',
                        align: 'center',
                    },
                    {
                        title: '文件名',
                        slot: 'play',
                        align: 'center',
                    },
                    {
                        title: '所属短视频',
                        key: 'videoName',
                        align: 'center',
                    },
                    {
                        title: '所属项目',
                        key: 'projectName',
                        align: 'center',
                    },
                    {
                        title: '编导',
                        key: 'directors',
                        align: 'center',
                    },
                    {
                        title: '达人',
                        key: 'stars',
                        align: 'center',
                    },
                    {
                        title: '上传者',
                        key: 'uploaderName',
                        align: 'center',
                    },
                    {
                        title: '上传时间',
                        key: 'uploadTime',
                        align: 'center',
                    },
                    {
                        title: '下载',
                        slot: 'download',
                        align: 'center',
                    },
                ],
                mTbData: [],
                mIsLoading: false,
                mPlayPath: '',
                mModalPlay: false,
            }
        },
        created() {
            if (!this.type) {
                g_func.errParam(this, '类型错误');
                this.$router.go(-1);
                return
            }
            switch (this.type) {
                case 1:
                    this.mTitle = '脚本库';
                    break;
                case 2:
                    this.mTitle = '编曲库';
                    break;
                case 10:
                    this.mTitle = '终版视频库';
                    break;
                case 3:
                    this.mTitle = '干音库';
                    break;
                case 4:
                    this.mTitle = '混音库';
                    break;
                case 5:
                    this.mTitle = '拍摄视频素材库';
                    break;
                default:
                    this.mTitle = '未知';
                    break;
            }
            this.search(0);
        },
        methods: {
            search(n) {
                this.mCurrentPage = n;
                let page = n <= 0 ? 0 : n - 1;
                let pageCount = 10;
                this.mIsLoading = true;
                this.mTbData = [];
                this.mCurrentPage = 1;
                g_func.getResource(this.type, page * pageCount, pageCount, (res) => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `获取资源库失败`)) {
                        return 0;
                    }
                    this.mTotalPage = Math.ceil(res.msg.total / pageCount) * 10;
                    res.msg.data.forEach((v, k) => {
                        v.index = k + 1;
                        v.uploaderName = v.uploader.name;
                        v.videoName = v.video.name;
                        v.projectName = v.project.name;
                        this.mTbData.push(v);
                    });
                });
            },
            play(r) {
                this.mPlayPath = r.path;
                this.mModalPlay = true;
            },
            cancel() {
                this.mPlayPath = '';
            },
            showPlay(path) {
                return path.match(/.mp3$|.mp4$|.mov$|.webm$|.ogg$|.wav$|.flv$/i)
            }
        }
    }
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>