<template>
    <div>
        <div class="add-video-input item-space" v-for="it in mSelectStars" :key="`div${it.index}`">
            <span class="add-video-input-title title">达人：</span>
            <Select class="add-video-input-select" placeholder="请选择" v-model="it.select"
                    @on-change="(n)=> {it.selectId=n.tag;}" label-in-value>
                <Option v-for="item in mStars" :value="item.name" :key="item.id" :tag="item.id">{{item.name}}
                </Option>
            </Select>
            <div class="item-delete" @click="delItem(it.selectId)">
                <Icon type="ios-remove-circle-outline" size="20" color="red"/>
            </div>
        </div>
        <div class="item-space">
            <studio
                    ref="studio"
                    :show-btn="true"
                    :hide-reserve="true"
            >
            </studio>
        </div>
        <div class="item-space">
            <user-work-list
                    :career-id="mWorkListCareerId"
                    ref="uwl"
            >
            </user-work-list>
        </div>
    </div>
</template>

<script>
    import Studio from "./Studio";
    import UserWorkList from "./UserWorkList";
    import {g_user} from "../source";

    export default {
        name: "AddOrderRecord",
        components: {UserWorkList, Studio},
        props: ['video'],
        data() {
            return {
                mWorkListCareerId: g_user.careerRecorderId,
                mStars: [],
                mSelectStars: [],
                mStyle: '',
                mDescription: '',
            }
        },
        watch: {
            video() {
                this.videoChange();
            }
        },
        created() {
            this.videoChange();
        },
        methods: {
            delItem(id) {
                this.mSelectStars = this.mSelectStars.filter((v) => {
                    return v.selectId !== id;
                })
            },
            videoChange() {
                if (!this.video || !this.video.id) {
                    return 0;
                }
                this.mStars = [];
                this.mSelectStars = [];
                this.video.stars.forEach((v) => {
                    this.mStars.push({
                        id: v.s.id,
                        name: v.s.name,
                    });
                    this.mSelectStars.push({
                        index: this.mSelectStars.length,
                        select: v.s.name,
                        selectId: v.s.id,
                    })
                });
            },
        },
    }
</script>

<style scoped src="../css/order.css">
</style>