<template>
    <div>
        <page-header
                :title-txt="'添加创意'"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: center; ">
            <div style="width: 900px; text-align: left">
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">名称：</span>
                    <Input class="add-video-input-select" v-model="mName"/>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">链接：</span>
                    <Input class="add-video-input-select" placeholder="输入链接地址或者上传文件" v-model="mPath"/>
                </div>
                <div class="add-video-input item-space">
                    <upload-item :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                                 :btn-txt="`上传文件`"
                                 :hide-path="true"
                                 @ok="upload($event, arguments)">
                        >
                    </upload-item>
                </div>
                <div class="add-video-input item-space btn">
                    <Button :loading="mIsLoading" class="btn-do" type="primary" @click="add">添加创意</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import UploadItem from "./UploadItem";
    import {g_func} from "../source";

    export default {
        name: "AddIdea",
        components: {UploadItem, PageHeader},
        data() {
            return {
                mName: '',
                mPath: '',
                mIsLoading: false,
            }
        },
        methods: {
            upload(n) {
                this.mPath = n;
            },
            add() {
                if (!this.mName || !this.mPath) {
                    g_func.errParam(this, '请输入名称和链接');
                    return;
                }
                g_func.addIdea(this.mName, this.mPath, res => {
                    if (!g_func.checkResCode(this, res, '添加创意库失败')) {
                        return
                    }
                    this.$router.push({path: '/resourceIdea'});
                });
            }
        }
    }
</script>

<style scoped src="../css/order.css">

</style>