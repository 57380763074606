<template>
    <div>
        <div class="add-video-input item-space" v-if="showBtn && mIsCareerDir">
            <span class="add-video-input-title title">预约录音棚：</span>
            <div style="width:150px; margin-right: 5px">
                <Select placeholder="请选择录音棚" v-model="mSelectStudio">
                    <Option v-for="item in mStudios" :value="item.title" :key="item.title">{{item.title}}
                    </Option>
                </Select>
            </div>
            <DatePicker class="add-video-input-select" type="datetimerange" format="M月d日 HH:mm" placeholder="选择预约时间"
                        v-model="mSelectTime"/>
            <Button type="primary" style="margin-left: 20px; width: 80px" @click="reserve" v-if="!hideReserve">预约
            </Button>
        </div>
        <div class="add-video-input item-space">
            <Tabs type="line" :animated="false" style="width: 100%" @on-click="onBtnStudioList">
                <TabPane v-for="it in mStudios.filter((v) => {return v.title !== '不预约'})" :key="it.title"
                         :label="it.title">
                    <div class="item-space">
                        <Table :loading="mIsLoading" :columns="mTbTitle" :data="mTbData" :show-header="false"
                               :border="true"
                               :row-class-name="setTitle"
                        >
                        </Table>
                    </div>
                </TabPane>
            </Tabs>
        </div>
        <Modal
                v-model="mDetail.show"
                title="录音棚预约详情"
                :closable="false"
                :footer-hide="true"
        >
            <div style="margin-bottom: 30px">
                <Spin v-if="mDetail.loading" size="large" fix></Spin>
                <div class="detailTitleBorder">
                    <div class="detailTitle">录音棚</div>
                    <span>{{mDetail.name}}</span></div>
                <div class="detailTitleBorder">
                    <div class="detailTitle">时间</div>
                    <span>{{mDetail.time}}</span></div>
                <div class="detailTitleBorder">
                    <div class="detailTitle">录音师</div>
                    <span>{{mDetail.recorder}}</span></div>
                <div class="detailTitleBorder">
                    <div class="detailTitle">短视频</div>
                    <span>{{mDetail.video}}</span></div>
                <div class="detailTitleBorder">
                    <div class="detailTitle">编导</div>
                    <span>{{mDetail.director}}</span></div>
                <div class="detailTitleBorder">
                    <div class="detailTitle">达人</div>
                    <span>{{mDetail.star}}</span></div>
            </div>
        </Modal>
    </div>
</template>
<style>
    .tbLine {
        font-weight: 700;
    }

    .detailTitle {
        margin-right: 2rem;
        margin-bottom: 6px;
        font-weight: 600;
        width: 46px;
        text-align: justify;
        text-align-last: justify;

    }

    .detailTitleBorder {
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 16px;
        display: flex;
        border-bottom: solid 1px black;
    }
</style>

<script>
    import {g_func, g_user} from "../source";

    export default {
        name: "Studio",
        props: ['showBtn', 'hideReserve'],
        data() {
            return {
                mStudios: [],
                mSelectStudio: '',
                mIsLoading: false,
                mCurStudio: 0,
                mSelectTime: '',
                mIsCareerDir: false,
                mTbData: [],
                mDetail: {
                    show: false,
                    loading: false,
                    name: '',
                    time: '',
                    director: '',
                    star: '',
                    recorder: '',
                },
                mTbTitle: [
                    {
                        "title": "d1",
                        "key": "d1",
                        "cIndex": 0,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d2",
                        "key": "d2",
                        "cIndex": 1,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d3",
                        "key": "d3",
                        "cIndex": 2,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d4",
                        "key": "d4",
                        "cIndex": 3,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d5",
                        "key": "d5",
                        "cIndex": 4,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d6",
                        "key": "d6",
                        "cIndex": 5,
                        "align": 'center',
                        render: this.tableRender,
                    },
                    {
                        "title": "d7",
                        "key": "d7",
                        "cIndex": 6,
                        "align": 'center',
                        render: this.tableRender,
                    },
                ],
                mRecorderWork: {},
            }
        },
        created() {
            this.mIsCareerDir = g_user.getCareerId() === g_user.careerDirectorId;

            // 获取录音棚
            g_func.getStudios((res) => {
                if (!g_func.checkResCode(this, res, `获取录音棚失败`)) {
                    return 0;
                }
                if (res.msg.studios.length === 0) {
                    return;
                }
                res.msg.studios.forEach(v => {
                    this.mStudios.push({
                        title: v,
                    });
                });
                this.mStudios.unshift({
                    title: '不预约',
                });
                this.onBtnStudioList(0);
            });
        },
        methods: {
            tableRender(h, p) {
                let d = p.row.[p.column.key];
                return [h('span',
                    {
                        domProps: {
                            innerHTML: d.name,
                            style: 'margin-right:0.5rem'
                        }
                    },
                ),
                    h('a',
                        {
                            domProps: {
                                innerHTML: d.id ? '[详情]' : '',
                            },
                            on: {
                                click: () => {
                                    this.showDetail(d);
                                }
                            }
                        },
                    ),
                ]
            },
            showDetail(d) {
                if (!d.id) {
                    g_func.info(this, '获取录音棚详情', '没有该录音棚信息')
                    return
                }
                this.mDetail.show = true;
                this.mDetail.loading = true;
                g_func.getStudioOrderDetail(d.id, res => {
                    this.mDetail.loading = false;
                    if (!g_func.checkResCode(this, res, `获取录音棚详情失败`)) {
                        return 0;
                    }
                    let o = res.msg.data;
                    let desc = JSON.parse(o.studio);
                    this.mDetail.director = o.director;
                    this.mDetail.name = desc.studio;
                    this.mDetail.time = `${desc.begin} ~ ${desc.end}`;
                    this.mDetail.recorder = o.recorder;
                    this.mDetail.star = o.stars;
                    this.mDetail.video = o.videoName;
                })
            },
            getSelectStudio() {
                let data = {
                    studio: this.mSelectStudio === '不预约' ? '' : this.mSelectStudio,
                    begin: g_func.getDatetime(this.mSelectTime[0]),
                    end: g_func.getDatetime(this.mSelectTime[1]),
                };
                if (data.studio !== '' && (data.begin === '' || data.end === '')) {
                    g_func.errParam(this, '请选择预约时间');
                    return
                }
                return data;
            },
            onBtnStudioList(i) {
                this.mCurStudio = i;
                let t = this.mStudios[i + 1];
                this.mIsLoading = true;
                g_func.getStudioList(t.title, res => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `获取排期失败`)) {
                        return 0;
                    }
                    this.mTbData = [];
                    let list = res.msg.list;
                    let maxLength = 0;
                    list.forEach(v => {
                        if (v.data.length > maxLength) {
                            maxLength = v.data.length;
                        }
                    });
                    let push = (d1, d2, d3, d4, d5, d6, d7) => {
                        this.mTbData.push({
                            d1: d1, d2: d2, d3: d3, d4: d4, d5: d5, d6: d6, d7: d7
                        })
                    };
                    let newNode = (n, id) => {
                        return {
                            id: id ? id : 0,
                            name: n ? n : '',
                        }
                    }
                    push(newNode(list[0].date), newNode(list[1].date), newNode(list[2].date), newNode(list[3].date), newNode(list[4].date), newNode(list[5].date), newNode(list[6].date));
                    for (let i = 0; i < maxLength; i++) {
                        let item = (l) => {
                            return i < l.data.length ? newNode(l.data[i].name, l.data[i].id) : newNode();
                        };
                        push(item(list[0]), item(list[1]), item(list[2]), item(list[3]), item(list[4]), item(list[5]), item(list[6]));
                    }
                });
            },
            setTitle(r, i) {
                if (i === 0) {
                    return 'tbLine';
                }
                return '';
            },
            reserve() {
                let data = this.getSelectStudio();
                if (!data.studio || !data.begin || !data.end) {
                    g_func.errParam(this, '请选择录音棚并设置时间');
                    return
                }
                g_func.requestStudio(data.studio, data.begin, data.end, res => {
                    if (!g_func.checkResCode(this, res, `预约录音棚失败`)) {
                        return 0;
                    }
                    g_func.info(this, '预约成功', '');
                    this.onBtnStudioList(this.mCurStudio);
                })
            }
        }
    }
</script>

<style scoped src="../css/order.css">
    @keyframes ani-demo-spin {
        from {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .demo-spin-col {
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>