<template>
    <div>
        <page-header
                :title-txt="'审核《编曲需求单》'"
        >
        </page-header>
        <div class="center">
            <div style="width: 900px; text-align: left">
                <div class="block-title">视频信息</div>
                <video-info-base
                        :director="mDirector"
                        :star="mStar"
                        :description="mVideo.description"
                        :name="mVideo.title"
                >
                </video-info-base>
                <div class="block-title">编曲需求</div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">指定制作人：</span>
                    <Select class="add-video-input-select" placeholder="请选择" v-model="mSelectId">
                        <Option v-for="it in mProducerList" :value="it.id" :key="it.id">{{it.name}}
                        </Option>
                    </Select>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title2">风格：</span>
                    <Input class="add-video-input-select" v-model="mStyle" :readonly="true"/>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title2">参考曲目：</span>
                    <Input class="add-video-input-select" v-model="mReference" :readonly="true"/>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title2">编曲说明：</span>
                    <Input class="add-video-input-select" type="textarea" :rows="10" v-model="mDescription" :readonly="true"/>
                </div>
                <div class="item-space">
                    <Button :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                            @click="submit">同意编曲需求
                    </Button>
                    <Button :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                            @click="refuse">拒绝编曲需求
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoInfoBase from "./VideoInfoBase";
    import {g_func, g_user} from "../source";
    import PageHeader from "./PageHeader";

    export default {
        name: "MusicOrderConfirm",
        props: ['orderId'],
        components: {PageHeader, VideoInfoBase},
        data() {
            return {
                mVideo: {
                    id: 0,
                    description: '',
                    title: '',
                },
                mProducerList: [],
                mDirector: '',
                mStar: '',
                mStyle: '',
                mSelectId: 0,
                mReference: '',
                mDescription: '',
                mIsLoading: false,
            }
        },
        created() {
            if (!this.orderId) {
                g_func.errParam(this, 'videoId或者orderId为空');
                this.$router.push({name: 'MyWork'});
                return
            }
            g_func.getOrderById(this.orderId, res => {
                if (!g_func.checkResCode(this, res, '获取需求单详情失败')) {
                    return;
                }
                if (res.msg.order.stat !== 1) {
                    g_func.info(this, '提示', '已完成审核');
                    this.$router.go(-1);
                    return;
                }
                this.mSelectId = res.msg.order.finishUser.id;
                this.mVideo.id = res.msg.order.videoId;
                let info = JSON.parse(res.msg.order.description);
                this.mStyle = info.style;
                this.mReference = info.references;
                this.mDescription = info.description;

                g_func.getVideoById(this.mVideo.id, res => {
                    if (!g_func.checkResCode(this, res, '获取短视频详情失败')) {
                        return;
                    }
                    this.mVideo = res.msg;
                    res.msg.stars.forEach((v) => {
                        if (this.mStar !== '') {
                            this.mStar += '，'
                        }
                        this.mStar += v.s.name;
                    });
                    res.msg.stars.forEach((v) => {
                        if (this.mDirector.indexOf(v.d.name) === -1) {
                            if (this.mDirector !== '') {
                                this.mDirector += '，'
                            }
                            this.mDirector += v.d.name;
                        }
                    });
                });

            });
            g_func.getUsers(g_user.careerProducerId, res => {
                if (!g_func.checkResCode(this, res, '获取制作人失败')) {
                    return;
                }
                res.msg.users.forEach((v) => {
                    this.mProducerList.push({
                        id: v.id,
                        name: v.n,
                    });
                });
            });
        },
        methods: {
            submit() {
                this.mIsLoading = true;
                g_func.confirmMusic(this.orderId, this.mSelectId, JSON.stringify({
                    style: this.mStyle,
                    references: this.mReference,
                    description: this.mDescription,
                }), res => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, '审核编曲需求单失败')) {
                        return;
                    }
                    g_func.info(this, '操作成功', '审核编曲需求成功');
                    this.$router.push({name: 'MyWork'});
                })
            },
            refuse() {
                this.mIsLoading = true;
                g_func.refuseMusic(this.orderId, res => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, '拒绝编曲需求单失败')) {
                        return;
                    }
                    g_func.info(this, '操作成功', '已拒绝编曲需');
                    this.$router.push({name: 'MyWork'});
                })
            }
        }
    }
</script>

<style scoped src="../css/order.css">

</style>