<template>
    <div>
        <div style="width:100%" v-if="mShowTabHeader">
            <div style="display: flex; margin-bottom: 40px">
                <Button :class="tab1Class"
                        @click="()=>{this.mShowProject = true; this.tab1Class = 'tab tab-selected'; this.tab2Class='tab';}">
                    项目信息
                </Button>
                <Button :class="tab2Class"
                        @click="()=>{this.mShowProject = false; this.tab1Class = 'tab'; this.tab2Class='tab tab-selected';}"
                        style="border-left: none">视频制作
                </Button>
            </div>
        </div>

        <div v-if="mShowProject">
            <page-header
                    :title-txt="mTitle"
                    v-if="!mShowTabHeader"
            >
            </page-header>
            <div style="display: flex; flex-direction: row; justify-content: center">
                <div style="width: 900px; text-align: left">
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">项目名称：</span>
                        <Input class="add-video-input-select" v-model="mProject.name" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">项目类型：</span>
                        <Input class="add-video-input-select" v-model="mProject.type" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="add-video-input-title title">品牌方：</span>
                        <Input class="add-video-input-select" v-model="mProject.company" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space" v-if="mProject.typeId === 2">
                        <span class="add-video-input-title title">推广歌曲：</span>
                        <Input class="add-video-input-select" v-model="mProject.musicName" :readonly="true"/>
                    </div>
                    <div class="add-video-input item-space desc">
                        <span class="add-video-input-title title">项目简述：</span>
                        <label class="add-video-input-select">
                            <Input type="textarea" :rows="10" v-model="mProject.desc" :readonly="true"/>
                        </label>
                    </div>

                    <div v-if="!mPage.bProjectConfirm">
                        <div class="add-video-input item-space" v-for="it in mStars" :key="it.index">
                            <span class="add-video-input-title title">指定达人</span>
                            <Input class="add-video-input-select" v-model="it.star.name" :readonly="true"/>
                        </div>
                    </div>

                    <page-header v-if="mPage.bProjectConfirm"
                                 style="margin-top: 40px"
                                 :title-txt="'设置项目信息'"
                    >
                    </page-header>

                    <div v-if="mPage.bProjectConfirm">
                        <div class="add-video-input item-space" v-for="it in mStars" :key="it.id">
                            <span class="add-video-input-title title">指定达人</span>
                            <Select class="add-video-input-select" placeholder="请选择" v-model="it.select">
                                <Option v-for="item in mAllStars" :value="item.id" :key="item.id">{{item.name}}
                                </Option>
                            </Select>
                            <div class="item-delete" @click="delStar(it)">
                                <Icon type="ios-remove-circle-outline" size="20" color="red"/>
                            </div>
                        </div>
                        <div class="item-space">
                            <Button type="primary" @click="addStar">添加达人</Button>
                        </div>
                    </div>

                    <div>
                        <div class="add-video-input item-space">
                            <span class="title2 add-video-input-title">脚本提交时间：</span>
                            <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                        placeholder="选择预约时间"
                                        v-model="mProject.scriptT" :readonly="mReadOnly"/>
                            <div class="origin-time" v-if="!mReadOnly">
                                <span>需求时间：</span>
                                <span class="origin-time-input">{{mProject.scriptTOrigin}}</span>
                            </div>
                        </div>
                        <div v-if="mProject.typeId === 1">
                            <div class="add-video-input item-space">
                                <span class="title2 add-video-input-title">物料提交时间：</span>
                                <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                            placeholder="选择预约时间"
                                            v-model="mProject.materialT" :readonly="mReadOnly"/>
                                <div class="origin-time" v-if="!mReadOnly">
                                    <span>需求时间：</span>
                                    <span class="origin-time-input">{{mProject.materialTOrigin}}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="mProject.typeId === 2">
                            <div class="add-video-input item-space">
                                <span class="title2 add-video-input-title">编曲提交时间：</span>
                                <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                            placeholder="选择预约时间"
                                            v-model="mProject.musicT" :readonly="mReadOnly"/>
                                <div class="origin-time" v-if="!mReadOnly">
                                    <span>需求时间：</span>
                                    <span class="origin-time-input">{{mProject.musicTOrigin}}</span>
                                </div>
                            </div>
                            <div class="add-video-input item-space">
                                <span class="title2 add-video-input-title">录音提交时间：</span>
                                <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                            placeholder="选择预约时间"
                                            v-model="mProject.recordT" :readonly="mReadOnly"/>
                                <div class="origin-time" v-if="!mReadOnly">
                                    <span>需求时间：</span>
                                    <span class="origin-time-input">{{mProject.recordTOrigin}}</span>
                                </div>
                            </div>
                            <div class="add-video-input item-space">
                                <span class="title2 add-video-input-title">混音提交时间：</span>
                                <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                            placeholder="选择预约时间"
                                            v-model="mProject.mixT" :readonly="mReadOnly"/>
                                <div class="origin-time" v-if="!mReadOnly">
                                    <span>需求时间：</span>
                                    <span class="origin-time-input">{{mProject.mixTOrigin}}</span>
                                </div>
                            </div>
                            <div class="add-video-input item-space">
                                <span class="title2 add-video-input-title">拍摄提交时间：</span>
                                <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                            placeholder="选择预约时间"
                                            v-model="mProject.shootT" :readonly="mReadOnly"/>
                                <div class="origin-time" v-if="!mReadOnly">
                                    <span>需求时间：</span>
                                    <span class="origin-time-input">{{mProject.shootTOrigin}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="add-video-input item-space" v-if="!mPage.bProjectConfirm">
                            <span class="title2 add-video-input-title">上线时间：</span>
                            <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                        placeholder="选择预约时间"
                                        v-model="mProject.onlineT" :readonly="true"/>
                        </div>
                        <div class="add-video-input item-space" v-if="mPage.bProjectConfirm">
                            <span class="title2 add-video-input-title">成片时间：</span>
                            <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                        placeholder="选择预约时间"
                                        v-model="mProject.videoT"/>
                            <div class="origin-time" v-if="!mReadOnly">
                                <span>需求上线时间：</span>
                                <span class="origin-time-input">{{mProject.onlineTOrigin}}</span>
                            </div>
                        </div>
                    </div>

                    <div data-mark="审核立项" v-if="mPage.bProjectAgree">
                        <page-header style="margin-top: 50px"
                                     :title-txt="'指定项目经理'"
                        >
                        </page-header>
                        <div class="add-video-input item-space">
                            <span class="add-video-input-title title">项目经理：</span>
                            <Select class="add-video-input-select" placeholder="请选择" v-model="mSelectProjectManagerId">
                                <Option v-for="it in mProjectManager" :value="it.id" :key="it.id">{{it.name}}
                                </Option>
                            </Select>
                        </div>
                        <div class="item-space">
                            <Button :loading="mBtnLoading" size="large" type="primary"
                                    style="width:100%;margin-top: 20px"
                                    @click="agree">同意立项
                            </Button>
                            <Button :loading="mBtnLoading" size="large" type="primary"
                                    style="width:100%;margin-top: 20px"
                                    @click="refuse">拒绝立项
                            </Button>
                        </div>
                    </div>

                    <div data-mark="确认项目信息" v-if="mPage.bProjectConfirm">

                        <div class="item-space">
                            <Button :loading="mBtnLoading" size="large" type="primary"
                                    style="width:100%;margin-top: 20px"
                                    @click="confirm">设置项目信息
                            </Button>
                        </div>
                    </div>

                    <div data-mark="验收项目" v-if="mPage.bProjectDone">
                        <div class="item-space">
                            <Button :loading="mBtnLoading" size="large" type="primary"
                                    style="width:100%;margin-top: 20px"
                                    @click="done">验收项目
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!mShowProject">
            <Tabs type="card">
                <Button v-if="mIsBtnCreateVideo" @click="createVideo" slot="extra" type="primary">新建短视频</Button>
                <TabPane v-for="it in mProjectVideos" :key="it.id" :label="it.name">
                    <video-info :video-id="it.id" :hide-title="true" style="margin-top: 40px">
                    </video-info>
                </TabPane>
            </Tabs>
        </div>
    </div>
</template>

<script>
    import {g_func, g_user} from "../source";
    import PageHeader from "./PageHeader";
    import VideoInfo from "./VideoInfo";

    export default {
        name: "ProjectAction",
        components: {
            VideoInfo,
            PageHeader
        },
        props: ['projectId'],
        data() {
            return {
                mTitle: '',
                mIsAdmin: g_user.isAdmin(),
                mIsProjectManager: g_user.isProjectManager(),
                mIsBusiness: g_user.isBusiness(),
                mIsBtnCreateVideo: g_user.getCareerId() === g_user.careerDirectorId,
                mProject: {
                    id: 0,
                    name: '',
                    type: '',
                    typeId: 0,
                    desc: '',
                    company: '',
                    musicName: '',
                    scriptT: '',
                    materialT: '',
                    onlineT: '',
                    musicT: '',
                    recordT: '',
                    videoT: '',
                    mixT: '',
                    shootT: '',
                    scriptTOrigin: '',
                    materialTOrigin: '',
                    onlineTOrigin: '',
                    musicTOrigin: '',
                    recordTOrigin: '',
                    mixTOrigin: '',
                    shootTOrigin: '',
                    stat: 0,
                },
                mStars: [],
                mAllStars: [],
                mReadOnly: true,
                mBtnLoading: false,
                mProjectManager: [],
                mSelectProjectManagerId: 0,
                mPage: {
                    bProjectAgree: false,
                    bProjectConfirm: false,
                    bProjectDone: false,
                },
                mShowTabHeader: false,
                mShowProject: true,
                mProjectVideos: [],
                tab1Class: 'tab tab-selected',
                tab2Class: 'tab',
            }
        },
        created() {
            /*
            审核立项: 修改指定项目经理 - 管理员操作
            确认项目信息：修改达人和时间信息 - 指定的项目经理
            验收：无修改
            */
            if (!this.projectId) {
                g_func.errParam(this, '项目ID错误');
                return
            }
            g_func.getProjectById(this.projectId, res => {
                if (!g_func.checkResCode(this, res, `获取项目信息失败`)) {
                    return 0;
                }
                let p = res.msg.project;
                this.mProject = p;
                this.mProject.typeId = p.type;
                this.mProject.type = p.type === 1 ? '广告项' : '歌曲推广项';
                this.mTitle = this.titleShow(p.stat);
                this.mShowTabHeader = this.mProject.stat === 3;
                this.mPage.bProjectAgree = this.mProject.stat === 1 && this.mIsAdmin; // 审核立项: 管理员，项目处于申请立项状态
                this.mPage.bProjectConfirm = this.mProject.stat === 2 && (this.mIsAdmin || this.mIsProjectManager); // 确认项目信息
                this.mPage.bProjectDone = this.mProject.stat === 3 && (this.mIsAdmin || this.mIsBusiness || (this.mIsProjectManager && this.mProject.creator.id === g_user.getId())); // 验收项目（管理员、商务、创建项目的项目经理）
                this.mReadOnly = !this.mPage.bProjectConfirm;
                this.mProject.scriptTOrigin = p.scriptT;
                this.mProject.materialTOrigin = p.materialT;
                this.mProject.onlineTOrigin = p.onlineT;
                this.mProject.musicTOrigin = p.musicT;
                this.mProject.recordTOrigin = p.recordT;
                this.mProject.mixTOrigin = p.mixT;
                this.mProject.shootTOrigin = p.shootT;

                if (this.mPage.bProjectAgree) {
                    this.getProjectManager();
                }
                if (this.mPage.bProjectConfirm) {
                    this.getStars();
                }
                if (this.mShowTabHeader) {
                    this.getProjectVideos();
                }
            });

            this.getProjectStar();
        },
        methods: {
            getProjectVideos() {
                g_func.getProjectVideos(this.mProject.id, res => {
                    if (!g_func.checkResCode(this, res, `获取项目短视频`)) {
                        return 0;
                    }
                    res.msg.data.forEach(v => {
                        this.mProjectVideos.push(v);
                    });
                    console.log(this.mProjectVideos)
                })
            },
            getStars() {
                g_func.getStars((res) => {
                    if (!g_func.checkResCode(this, res, `获取达人失败`)) {
                        return 0;
                    }
                    this.mAllStars = [];
                    res.msg.stars.forEach((v) => {
                        this.mAllStars.push({
                            id: v.s.id,
                            name: v.s.n,
                        });
                    });
                });
            },
            getProjectManager() {
                g_func.getProjectManager(res => {
                    if (!g_func.checkResCode(this, res, `获取项目管理员失败`)) {
                        return 0;
                    }
                    this.mProjectManager = res.msg.users;
                });
            },
            getProjectStar() {
                g_func.getProjectStar(this.projectId, res => {
                    if (!g_func.checkResCode(this, res, `获取项目达人失败`)) {
                        return 0;
                    }
                    this.mStars = [];
                    res.msg.stars.forEach((v, i) => {
                        this.mStars.push({
                            index: i,
                            id: (new Date()).getTime().toString() + this.mStars.length,
                            select: v.s.id,
                            director: {
                                id: v.d.id,
                                name: v.d.name,
                                disabled: true,
                            },
                            star: {
                                id: v.s.id,
                                name: v.s.name,
                                disabled: true,
                            },
                        });
                    });
                });
            },
            delProjectItem(index) {
                this.mStars = this.mStars.filter((v) => {
                    return v.index !== index;
                });
            },
            titleShow(stat) {
                switch (stat) {
                    case 1:
                        return '审核立项';
                    case 2:
                        return '确认项目信息';
                    case 3:
                        return '验收项目';
                    default:
                        return '项目信息'
                }
            },
            addStar() {
                this.mStars.push({
                    id: (new Date()).getTime().toString() + this.mStars.length,
                    index: this.mStars.length,
                    select: 0,
                });
            },
            delStar(it) {
                this.mStars = this.mStars.filter(v => {
                    return v.id !== it.id;
                });
            },
            agree() {
                if (!this.mSelectProjectManagerId) {
                    g_func.errParam(this, '请指定项目经理');
                    return
                }
                this.mBtnLoading = true;
                g_func.agreeProject(this.mProject.id, this.mSelectProjectManagerId, res => {
                    this.mBtnLoading = false;
                    if (!g_func.checkResCode(this, res, `审核立项失败`)) {
                        return 0;
                    }
                    this.$Message.info(`操作成功`);
                    this.$router.go(-1)
                });
            },
            refuse() {
                this.mBtnLoading = true;
                g_func.refuseProject(this.mProject.id, res => {
                    this.mBtnLoading = false;
                    if (!g_func.checkResCode(this, res, `拒绝立项失败`)) {
                        return 0;
                    }
                    this.$Message.info(`操作成功`);
                    this.$router.go(-1)
                })
            },
            confirm() {
                let videoT = g_func.getDatetime(this.mProject.videoT);
                if (!videoT) {
                    g_func.errParam(this, '请输入成片提交时间');
                    return
                }

                let stars = [];
                this.mStars.forEach(v => {
                    if (parseInt(v.select)) {
                        stars.push(parseInt(v.select))
                    }
                });
                if (stars.length !== (new Set(stars)).size) {
                    g_func.errParam(this, '选择达人有重复');
                    return;
                }

                let b = {
                    projectId: this.mProject.id,
                    type: this.mProject.typeId,
                    stars: stars,
                    scriptT: g_func.getDatetime(this.mProject.scriptT),
                    videoT: g_func.getDatetime(this.mProject.videoT),
                };
                if (!b.scriptT) {
                    g_func.errParam(this, '请输入脚本提交时间')
                    return;
                }
                if (b.type === 1) {
                    b.materialT = g_func.getDatetime(this.mProject.materialT);
                    if (!b.materialT) {
                        g_func.errParam(this, '请输入物料提交时间')
                        return;
                    }
                } else {
                    b.musicT = g_func.getDatetime(this.mProject.musicT);
                    b.recordT = g_func.getDatetime(this.mProject.recordT);
                    b.mixT = g_func.getDatetime(this.mProject.mixT);
                    b.shootT = g_func.getDatetime(this.mProject.shootT);
                    if (!b.musicT) {
                        g_func.errParam(this, '请输入编曲提交时间')
                        return;
                    }
                    if (!b.recordT) {
                        g_func.errParam(this, '请输入录音提交时间')
                        return;
                    }
                    if (!b.mixT) {
                        g_func.errParam(this, '请输入混音提交时间')
                        return;
                    }
                    if (!b.shootT) {
                        g_func.errParam(this, '请输入拍摄提交时间')
                        return;
                    }
                }
                this.mBtnLoading = true;
                g_func.confirmProject(b, res => {
                    this.mBtnLoading = false;
                    if (!g_func.checkResCode(this, res, `确认项目信息失败`)) {
                        return 0;
                    }
                    this.$Message.info({content: '操作成功'});
                    this.$router.go(-1)
                });
            },
            done() {
                this.mBtnLoading = true;
                g_func.finishProject(this.mProject.id, res => {
                    this.mBtnLoading = false;
                    if (!g_func.checkResCode(this, res, `验收项目失败`)) {
                        return 0;
                    }
                    this.$Message.info(`操作成功`);
                    this.$router.go(-1)
                })
            },
            createVideo() {
                this.$router.push({path: 'addVideo', query: {projectId: this.mProject.id}});
            }
        }
    }
</script>

<style scoped src="../css/order.css">

</style>

<style scoped>
    .origin-time {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-left: 10%;
        width: 33%;
    }

    .origin-time-input {
        flex: 1;
        border: none;
    }

    .tab {
        width: 50%;
        border: solid 1px;
    }

    .tab-selected {
        font-weight: 700;
        color: dodgerblue;
    }
</style>