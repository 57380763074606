var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title-txt":'我的工作'}}),(_vm.mC.bShow)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" 审核 ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('Table',{attrs:{"border":"","loading":_vm.mC.isLoading,"columns":_vm.mC.tbColumn,"data":_vm.mC.tbData},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index+1))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.action(row, row.type.indexOf('立项') !== -1)}}},[_vm._v("审核 ")])]}}],null,false,700858073)})],1)]):_vm._e(),(_vm.mP.bShow)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" 商业项目 ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('Table',{attrs:{"border":"","loading":_vm.mP.isLoading,"columns":_vm.mP.tbColumn,"data":_vm.mP.tbData},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index+1))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.action(row, true)}}},[_vm._v("详情 ...")])]}}],null,false,345339352)})],1)]):_vm._e(),(_vm.mP2.bShow)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" 相关商业项目 ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('Table',{attrs:{"border":"","loading":_vm.mP2.isLoading,"columns":_vm.mP2.tbColumn,"data":_vm.mP2.tbData},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index+1))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.action(row, true)}}},[_vm._v("详情 ...")])]}}],null,false,345339352)})],1)]):_vm._e(),(_vm.mV.bShow)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" 短视频 ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('Table',{staticStyle:{"cursor":"pointer"},attrs:{"border":"","loading":_vm.mV.isLoading,"columns":_vm.mV.tbColumn,"data":_vm.mV.tbData},on:{"on-row-click":_vm.order},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index+1))])]}}],null,false,1334410277)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }