<template>
    <div class="login">
        <Input search enter-button="登录" placeholder="输入登录码" v-model="mLoginCode" @on-search="login"/>
    </div>
</template>

<script>
    import {g_func, g_user} from "../source";

    export default {
        name: "Login",
        data() {
            return {
                mLoginCode: '',
            }
        },
        methods: {
            login() {
                if (!this.mLoginCode) {
                    g_func.error(this, '请输入6位登录码');
                    return 0
                }
                g_user.login(this.mLoginCode, (res) => {
                    if (!g_func.checkResCode(this, res, '登录失效')) {
                        return 0
                    }
                    this.$emit('login');
                });
            },
        }
    }
</script>

<style scoped>


    .login {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        width: 30vw;
        min-width: 240px;
    }
</style>