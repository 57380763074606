<template>
    <div>
        <page-header
                :title-txt="'新建短视频'"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: center">
            <div style="width: 900px; text-align: left">
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">视频名称</span>
                    <Input class="add-video-input-select" placeholder="视频名称" v-model="mName"/>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">关联商业项目</span>
                    <Input v-if="projectId" class="add-video-input-select" v-model="mProjectName" :readonly="true"/>
                    <Select v-if="!projectId" class="add-video-input-select" placeholder="请选择" v-model="mProjectId"
                            @on-change="selectProject">
                        <Option v-for="item in projects" :value="item.id" :key="item.id">{{item.name}}
                        </Option>
                    </Select>
                </div>

                <div class="star item-space" v-for="it in projectStar" :key="it.index">
                    <span class="add-video-input-title">指定编导</span>
                    <div class="add-video-input-select">
                        <Input v-model="it.director.name" :readonly="true"/>
                    </div>
                    <span class="add-video-input-title add-video-input-select-right">指定达人</span>
                    <div class="add-video-input-select">
                        <Input v-model="it.star.name" :readonly="true"/>
                    </div>
                    <div class="item-delete" @click="delProjectItem(it.index)">
                        <Icon type="ios-remove-circle-outline" size="20" color="red"/>
                    </div>
                </div>

                <div class="star item-space" v-for="it in addStar" :key="it.index">
                    <span class="add-video-input-title" style="width: 60px">指定编导</span>
                    <Select class="add-video-input-select" placeholder="请选择"
                            @on-change="selectDirector($event, it)">
                        <Option v-for="item in mDirectors" :value="item.id" :key="item.id">{{item.n}}
                        </Option>
                    </Select>
                    <span class="add-video-input-title add-video-input-select-right" style="width: 60px">指定达人</span>
                    <Select class="add-video-input-select" placeholder="请选择" v-model="it.selectStarId">
                        <Option v-for="item in it.stars" :value="item.id" :key="item.id"
                        >{{item.name}}
                        </Option>
                    </Select>
                    <div class="item-delete" @click="delStarItem(it)">
                        <Icon type="ios-remove-circle-outline" size="20" color="red"/>
                    </div>
                </div>

                <div style="margin-bottom: 15px">
                    <Button type="primary" @click="starAdd">添加编导、达人</Button>
                </div>
                <div style="margin-bottom: 15px">
                    <div style="margin-bottom: 15px" class="title">视频说明</div>
                    <Input type="textarea" v-model="description" :rows='10' placeholder="视频说明"/>
                </div>
                <div>
                    <Button :loading="isLoading" type="primary" style="width:100%" @click="addVideo">创建短视频</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func, g_user} from "../source";

    export default {
        name: "AddVideo",
        components: {PageHeader},
        props: ['projectId'],
        data() {
            return {
                mName: '',
                mProjectId: this.projectId,
                mProjectName: '',
                projectStar: [],
                projects: [],
                addStar: [],
                isLoading: false,
                description: '',
                mDirectors: [],
            }
        },
        created() {
            g_func.getUsers(g_user.careerDirectorId, (res) => {
                if (!g_func.checkResCode(this, res, '获取编导列表失败')) {
                    return;
                }
                this.mDirectors = res.msg.users;
            });

            // 已绑定了项目和未绑定项目
            if (this.projectId) {
                g_func.getProjectById(this.projectId, res => {
                    this.mProjectName = res.msg.project.name;
                });
                this.getProjectStar();
            } else {
                g_func.getProject('', 3, 0, 1000, (res) => {
                    if (!g_func.checkResCode(this, res, '获取项目信息失败')) {
                        return
                    }
                    this.projects.push({
                        id: 0,
                        name: '无',
                    });
                    res.msg.projects.forEach((vv) => {
                        this.projects.push({
                            id: vv.id,
                            name: vv.name,
                        });
                    });
                });
            }
        },
        methods: {
            selectProject(id) {
                this.projectStar = [];
                this.mProjectId = id;
                this.getProjectStar();
            },
            getProjectStar() {
                if (!this.mProjectId) {
                    return;
                }
                g_func.getProjectStar(this.mProjectId, (res) => {
                    if (!g_func.checkResCode(this, res, `获取项目达人失败`)) {
                        return 0;
                    }
                    res.msg.stars.forEach((v) => {
                        this.projectStar.push({
                            index: (new Date()).getTime().toString() + this.projectStar.length,
                            director: {
                                id: v.d.id,
                                name: v.d.name,
                                disabled: true,
                            },
                            star: {
                                id: v.s.id,
                                name: v.s.name,
                                disabled: true,
                            },
                        });
                    });
                });
            },
            starAdd() {
                let index = (new Date()).getTime().toString() + this.projectStar.length;
                this.addStar.push({
                    index: index,
                    stars: [],
                });
            },
            delProjectItem(i) {
                this.projectStar = this.projectStar.filter((v) => {
                    return v.index !== i;
                });
            },
            delStarItem(it) {
                this.addStar = this.addStar.filter((v) => {
                    return v.index !== it.index;
                });
            },
            selectDirector(id, it) {
                g_func.getStarByDirector(id, (res) => {
                    if (!g_func.checkResCode(this, res, '获取达人失败')) {
                        return
                    }
                    it.stars = [];
                    res.msg.stars.forEach((v) => {
                        it.stars.push({
                            id: v.id,
                            name: v.name,
                        });
                    });
                });
            },
            addVideo() {
                if (!this.mName) {
                    g_func.error(this, '短视频名称不能为空', '');
                    return
                }
                let stars = [];
                this.projectStar.forEach(v => {
                    stars.push(v.star.id);
                });

                console.log(this.addStar);
                this.addStar.forEach(v => {
                    if (v.selectStarId) {
                        stars.push(v.selectStarId)
                    }
                });
                if (stars.length === 0) {
                    g_func.error(this, '请添加达人', '');
                    return
                }

                let ss = [...new Set(stars)];
                if (ss.length !== stars.length) {
                    g_func.error(this, '项目指定达人重复，请重新选择达人', '');
                    return 0;
                }
                this.isLoading = true;
                g_func.addVideo({
                    t: this.mName,
                    p: parseInt(this.mProjectId),
                    stars: stars,
                    d: this.description,
                }, res => {
                    this.isLoading = false;
                    if (!g_func.checkResCode(this, res, '创建短视频失败')) {
                        return
                    }
                    g_func.info(this, '创建短视频成功');
                    this.$router.push({path: 'videoInfo', query: {videoId: res.msg.id}});
                })
            },
        },
    }
</script>

<style scoped src="../css/order.css">

</style>