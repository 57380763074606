<template>
    <div id="app">
        <Login v-if="!mLogin"
               @login="login"
        >
        </Login>
        <Index v-if="mLogin"
               @logout="logout"
        >
        </Index>
    </div>
</template>

<script>

    import {g_func, g_user} from "./source"
    import Login from "./components/Login";
    import Index from "./components/Index";

    export default {
        name: 'app',
        components: {Index, Login},
        data() {
            return {
                mLogin: g_user.isLogin(),
            }
        },
        methods: {
            login() {
                this.mLogin = true;
                this.$router.push({path:'myWork'});
            },
            logout() {
                this.mLogin = false;
                g_user.logout();
                this.$router.push({path:'/'});
            }
        },
        created() {
            g_func.setVueThis(this);
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
