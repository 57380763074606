export {g_user, g_func}

class User {
    constructor() {
        this.careerDirectorId = 1;
        this.careerProjectManager = 2;
        this.careerProducerId = 3;
        this.careerRecorderId = 4;
        this.careerMusicMixerId = 5;
        this.careerBusinessId = 6;
        this.careerStarId = 8;
    }

    isLogin() {
        return !!this.getToken()
    }

    isAdmin() {
        return !!parseInt(getCookie('a'))
    }

    isMusicConfirm() {
        return !!parseInt(getCookie('mc'))
    }

    isProjectManager() {
        return !!parseInt(getCookie('pm'))
    }

    isDirector() {
        return this.getCareerId() === this.careerDirectorId;
    }

    isBusiness() {
        return !!parseInt(getCookie('b'))
    }

    isDataRights() {
        let dr = parseInt(getCookie('dr'));
        return dr > 0 && dr < 8
    }

    isDataWorkRights() {
        return !!(parseInt(getCookie('dr')) & 0x1) || g_user.isAdmin()
    }

    isDataUserRights() {
        return !!(parseInt(getCookie('dr')) >> 1 & 0x1) || g_user.isAdmin()
    }

    isDataLiveRights() {
        return !!(parseInt(getCookie('dr')) >> 2 & 0x1) || g_user.isAdmin()
    }

    getToken() {
        return getCookie('token');
    }

    getId() {
        return parseInt(getCookie('id'));
    }

    logout() {
        delCookie('token');
    }

    getNick() {
        return unescape(getCookie('nick'));
    }

    getAvatar() {
        return unescape(getCookie('avatar'));
    }

    getCareerId() {
        return parseInt(getCookie('cId'));
    }

    login(code, callback) {
        g_func.login(code, res => {
            if (res.code === 0) {
                setCookie('token', res.msg.token);
                setCookie('nick', res.msg.name);
                setCookie('id', res.msg.id);
                setCookie('mc', res.msg.mc);
                setCookie('a', res.msg.a);
                setCookie('dr', res.msg.dr);
                setCookie('pm', (res.msg.pm === 1 || res.msg.c.id === this.careerProjectManager) ? 1 : 0);
                setCookie('b', res.msg.c.id === this.careerBusinessId ? 1 : 0);
                setCookie('cId', res.msg.c.id)
                setCookie('cName', res.msg.c.name)
            }
            if (callback) {
                callback(res);
            }
        });
    }
}

let g_user = new User();

class FuncInterface {

    constructor() {
        this.svr = 'https://sv.yuzijiang.tv';
        this.svr = 'https://sv.t.yuzijiang.tv';
        // this.svr = 'http://localhost:17000';
        this.vueThis = undefined;
    }

    setVueThis(p) {
        this.vueThis = p;
    }

    // 登录
    login(code, callback) {
        return this.requestPost(`${this.svr}/u/pc/login?code=${code}`, '', callback);
    }

    // 获取用户
    getUsers(n, callback) {
        return this.requestGet(`${this.svr}/a/users?t=${g_user.getToken()}&cId=${n}&s=0&c=1000`, callback);
    }

    // 职业列表
    getCareerList(callback) {
        return this.requestGet(`${this.svr}/u/careers?t=${g_user.getToken()}`, callback)
    }

    // 添加员工
    addUser(name, phone, careerId, pm, mc, admin, dr, idea, store, callback) {
        return this.requestPost(`${this.svr}/a/user/add?t=${g_user.getToken()}&n=${name}&p=${phone}&cId=${careerId}&pm=${pm}&mc=${mc}&a=${admin}&dr=${dr}&idea=${idea}&store=${store}`, '', callback);
    }

    // 编辑员工
    editUser(uid, name, pm, mc, admin, dr, idea, store, callback) {
        return this.requestPost(`${this.svr}/a/user/edit?t=${g_user.getToken()}&uid=${uid}&n=${name}&pm=${pm}&mc=${mc}&a=${admin}&dr=${dr}&idea=${idea}&store=${store}`, '', callback);
    }

    // 删除员工
    deleteUser(uid, callback) {
        return this.requestPost(`${this.svr}/a/user/delete?token=${g_user.getToken()}&uid=${uid}`, '', callback);
    }

    // 获取员工信息
    getUser(uid, callback) {
        return this.requestGet(`${this.svr}/a/user/info?t=${g_user.getToken()}&uid=${uid}`, callback);
    }

    // 获取员工信息
    getUserRights(uid, callback) {
        return this.requestGet(`${this.svr}/a/user/rights?t=${g_user.getToken()}&uid=${uid}`, callback);
    }

    // 添加达人
    addStar(name, phone, dId, callback) {
        return this.requestPost(`${this.svr}/a/star/add?t=${g_user.getToken()}&n=${name}&p=${phone}&dId=${dId}`, '', callback);
    }

    // 编辑达人
    editStar(id, name, dId, callback) {
        return this.requestPost(`${this.svr}/a/star/edit?t=${g_user.getToken()}&uid=${id}&n=${name}&dId=${dId}`, '', callback);
    }

    // 获取达人信息
    getStar(uid, callback) {
        return this.requestGet(`${this.svr}/a/star/info?t=${g_user.getToken()}&uid=${uid}`, callback);
    }

    // 获取达人
    getStars(callback) {
        return this.requestGet(`${this.svr}/a/stars?t=${g_user.getToken()}`, callback);
    }

    // 获取短视频列表
    getVideos(key, start, count, callback) {
        return this.requestGet(`${this.svr}/v/videos?t=${g_user.getToken()}&k=${key}&s=${start}&c=${count}`, callback);
    }

    // 获取项目列表
    getProject(key, st, start, count, callback) {
        return this.requestGet(`${this.svr}/p/s?t=${g_user.getToken()}&k=${key}&st=${st}&s=${start}&c=${count}`, callback);
    }

    // 获取项目关联短视频
    getProjectVideos(id, callback) {
        return this.requestGet(`${this.svr}/p/videos?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 获取项目
    getProjectById(id, callback) {
        return this.requestGet(`${this.svr}/p?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 立项
    addProject(b, callback) {
        return this.requestPost(`${this.svr}/p/add?t=${g_user.getToken()}`, b, callback)
    }

    // 审核立项
    agreeProject(id, pmId, callback) {
        return this.requestGet(`${this.svr}/p/agree?t=${g_user.getToken()}&id=${id}&pmId=${pmId}`, callback);
    }

    // 拒绝立项
    refuseProject(id, callback) {
        return this.requestGet(`${this.svr}/p/refuse?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 确认项目信息
    confirmProject(b, callback) {
        return this.requestPost(`${this.svr}/p/confirm?t=${g_user.getToken()}`, b, callback)
    }

    // 验收项目
    finishProject(id, callback) {
        return this.requestGet(`${this.svr}/p/finish?t=${g_user.getToken()}&id=${id}`, callback)
    }

    // 删除项目
    deleteProject(id, callback) {
        return this.requestGet(`${this.svr}/p/delete?t=${g_user.getToken()}&id=${id}`, callback)
    }

    // 获取项目达人编导
    getProjectStar(id, callback) {
        return this.requestGet(`${this.svr}/p/star?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 获取项目管理
    getProjectManager(callback) {
        return this.requestGet(`${this.svr}/p/manager?t=${g_user.getToken()}`, callback);
    }

    // 获取编导下达人
    getStarByDirector(directorId, callback) {
        return this.requestGet(`${this.svr}/u/stars?t=${g_user.getToken()}&dId=${directorId}`, callback);
    }

    // 添加短视频
    addVideo(b, callback) {
        return this.requestPost(`${this.svr}/v/add?t=${g_user.getToken()}`, b, callback);
    }

    // 获取短视频
    getVideoById(id, callback) {
        return this.requestGet(`${this.svr}/v/?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 获取需求单
    getOrderById(id, callback) {
        return this.requestGet(`${this.svr}/o/info?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 录音棚需求单详情
    getStudioOrderDetail(id, callback) {
        return this.requestGet(`${this.svr}/o/studio/detail?t=${g_user.getToken()}&id=${id}`, callback);
    }
    // 同意编曲需求
    confirmMusic(id, fUid, desc, callback) {
        return this.requestPost(`${this.svr}/o/music/confirm?t=${g_user.getToken()}&id=${id}&fUid=${fUid}&desc=${desc}`, '', callback);
    }

    // 拒绝编曲需求
    refuseMusic(id, callback) {
        return this.requestPost(`${this.svr}/o/music/refuse?t=${g_user.getToken()}&oId=${id}`, '', callback);
    }

    // 设置终版视频地址
    setOrderFile(id, path, callback) {
        console.log(encodeURIComponent(path));
        return this.requestGet(`${this.svr}/o/file/set?t=${g_user.getToken()}&path=${encodeURIComponent(path)}&id=${id}`, callback);
    }

    // 完成需求单
    finishOrder(id, c) {
        return this.requestGet(`${this.svr}/o/finish?t=${g_user.getToken()}&id=${id}`, c);
    }

    // 删除短视频
    delVideo(id, callback) {
        return this.requestGet(`${this.svr}/v/del?t=${g_user.getToken()}&id=${id}`, callback);
    }

    // 获取录音棚
    getStudios(callback) {
        return this.requestGet(`${this.svr}/o/studios?t=${g_user.getToken()}`, callback);
    }

    // 获取录音棚排期
    getStudioList(t, callback) {
        return this.requestGet(`${this.svr}/o/studio/list?t=${g_user.getToken()}&title=${t}`, callback);
    }

    // 获取工作列表
    getWorkList(uid, callback) {
        return this.requestGet(`${this.svr}/u/workList?t=${g_user.getToken()}&uid=${uid}`, callback);
    }

    // 获取职业工作列表
    getCareerWorkList(cId, callback) {
        return this.requestGet(`${this.svr}/u/careerVideo?t=${g_user.getToken()}&cId=${cId}`, callback);
    }

    // 获取待审核
    getMyWorkVerify(callback) {
        return this.requestGet(`${this.svr}/u/myWork/verify?t=${g_user.getToken()}`, callback);
    }

    // 获取我的工作中商业项目列表
    getMyWorkProject(callback) {
        return this.requestGet(`${this.svr}/u/myWork/project?t=${g_user.getToken()}`, callback);
    }

    // 获取我的工作中商业项目列表
    getDirectorProject(callback) {
        return this.requestGet(`${this.svr}/u/myWork/directorProject?t=${g_user.getToken()}`, callback);
    }

    // 获取我的工作中商业项目列表
    getMyWorkVideo(callback) {
        return this.requestGet(`${this.svr}/u/myWork/video?t=${g_user.getToken()}`, callback);
    }

    // 获取需求单中预约的录音棚
    getOrderStudioByOrderId(id, c) {
        return this.requestGet(`${this.svr}/o/studio/reserve?t=${g_user.getToken()}&id=${id}`, c);
    }

    // 预约录音棚
    requestStudio(name, begin, end, c) {
        return this.requestGet(`${this.svr}/o/studio/request?t=${g_user.getToken()}&studio=${name}&begin=${begin}&end=${end}`, c);
    }

    // 设置终版视频地址
    setVideoFile(id, path, callback) {
        return this.requestGet(`${this.svr}/v/file/set?t=${g_user.getToken()}&path=${path}&id=${id}`, callback);
    }

    // 上线短视频
    setVideoOnline(id, c) {
        return this.requestGet(`${this.svr}/v/finish?t=${g_user.getToken()}&id=${id}`, c);
    }

    // 获取上传文件token
    getUploadFileToken(c) {
        return this.requestGet(`${this.svr}/u/uploadToken?t=${g_user.getToken()}`, c);
    }

    // 获取数据短视频
    getDataCount(type, c) {
        return this.requestGet(`${this.svr}/data/count?t=${g_user.getToken()}&type=${type}`, c);
    }

    // 查询数据视频
    getDataTable(type, st, time, c) {
        return this.requestGet(`${this.svr}/data/search?t=${g_user.getToken()}&type=${type}&time=${time}&st=${st}`, c);
    }

    // 获取资源库
    getResource(type, start, count, c) {
        return this.requestGet(`${this.svr}/data/resource?t=${g_user.getToken()}&type=${type}&start=${start}&count=${count}`, c);
    }

    // 获取创意库
    getResourceIdea(start, count, c) {
        return this.requestGet(`${this.svr}/data/idea?t=${g_user.getToken()}&start=${start}&count=${count}`, c);
    }

    // 获取人员数据
    getDataUser(careerId, c) {
        return this.requestGet(`${this.svr}/data/users?t=${g_user.getToken()}&careerId=${careerId}`, c);
    }

    // 添加创意
    addIdea(name, path, c) {
        return this.requestGet(`${this.svr}/data/idea/add?t=${g_user.getToken()}&name=${name}&path=${path}`, c);
    }

    // 创建脚本需求单
    createOrder(type, data, callback) {
        let uri = '';
        switch (type) {
            case 1:
                uri = 'script/create';
                break;
            case 2:
                uri = 'music/create';
                break;
            case 3:
                uri = 'record/create';
                break;
            case 4:
                uri = 'mix/create';
                break;
            case 5:
                uri = 'shoot/create';
                break;
            default:
                console.log('wrong type');
                return;
        }
        return this.requestPost(`${this.svr}/o/${uri}?t=${g_user.getToken()}`, data, callback)
    }

    getScript(st) {
        switch (parseInt(st)) {
            case 1:
                return {type: 1, name: '脚本需求单'};
            case 2:
                return {type: 2, name: '编曲需求单'};
            case 3:
                return {type: 3, name: '录音需求单'};
            case 4:
                return {type: 4, name: '混音需求单'};
            case 5:
                return {type: 5, name: '拍摄需求单'};
        }
    }

    careerId2Title(id) {
        switch (id) {
            case 1:
                return "编导";
            case 2:
                return "项目经理";
            case 3:
                return "制作人";
            case 4:
                return "录音师";
            case 5:
                return "混音师";
            case 6:
                return "商务";
            case 7:
                return "运营";
            case 8:
                return "达人";
        }
    }

    upload(url, file, callback, progress) {
        console.log(url, file);
        let xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                if (callback) {
                    callback(JSON.parse(this.responseText));
                }
            }
        });
        xhr.upload.addEventListener('progress', progress);

        xhr.onerror = function (e) {
            if (e.type === 'error' && callback) {
                callback({code: -444, err: '请求失败，请检查网络'})
            }
        };

        let formData = new FormData();
        formData.append("file", file);
        xhr.open('POST', url);
        xhr.send(formData);
    }

    // 请求
    request(method, url, data, callback) {
        url = encodeURI(url);
        console.log(method, url, data);
        let xhr = new XMLHttpRequest();
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                if (callback) {
                    callback(JSON.parse(this.responseText));
                }
            }
        });
        xhr.onerror = function (e) {
            if (e.type === 'error' && callback) {
                callback({code: -444, err: '请求失败，请检查网络'})
            }
        };
        xhr.open(method, url);
        xhr.send(JSON.stringify(data));
    }

    requestGet(url, callback) {
        this.request("GET", url, '', callback);
    }

    requestPost(url, data, callback) {
        this.request("POST", url, data, callback);
    }

    checkResCode(p, res, t, onOk) {
        if (!onOk) {
            onOk = () => {
            }
        }
        if (res.code === 1001) {
            if (this.vueThis) {
                this.vueThis.logout();
            }
            return false
        }
        console.log(res)
        if (res.code) {
            p.$Modal.error({
                title: t, content: res.err, onOk: onOk
            });
            return false;
        }
        return true;
    }

    errParam(p, c) {
        this.error(p, `参数错误`, c);
    }

    error(p, t, c) {
        p.$Modal.error({
            title: t,
            content: c
        });
    }

    info(p, t, c) {
        p.$Modal.info({
            title: t,
            content: c
        });
    }

    doOk(p) {
        p.$Message.info('操作成功');
    }

    getDatetime(t) {
        if (!t) {
            return;
        }
        let year = t.getFullYear();
        let month = t.getMonth() + 1;
        let day = t.getDate();
        let hh = t.getHours();
        let mm = t.getMinutes();
        let ss = t.getSeconds();
        let clock = year + "-";
        if (month < 10)
            clock += "0";
        clock += month + "-";
        if (day < 10)
            clock += "0";
        clock += day + " ";
        if (hh < 10)
            clock += "0";
        clock += hh + ":";
        if (mm < 10) clock += '0';
        clock += mm + ":";
        if (ss < 10) clock += '0';
        clock += ss;
        return clock;
    }
}

let g_func = new FuncInterface();

function setCookie(name, value) {
    let exp = new Date();
    exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

function getCookie(name) {
    let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    if (arr != null) {
        return (arr[2]);
    } else {
        return "";
    }
}

function delCookie(name) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let val = getCookie(name);
    if (val != null) {
        document.cookie = name + "=" + val + ";expires=" + exp.toGMTString();
    }
}