<template>
    <div>
        <page-header
                :title-txt="'我的工作'"
        ></page-header>
        <div class="block" v-if="mC.bShow">
            <div class="title">
                审核
            </div>
            <div style="margin-top: 20px">
                <Table border :loading=mC.isLoading :columns="mC.tbColumn" :data="mC.tbData">
                    <template slot-scope="{index}" slot="index">
                        <span>{{index+1}}</span>
                    </template>
                    <template slot-scope="{row}" slot="action">
                        <Button type="primary" size="small" @click="action(row, row.type.indexOf('立项') !== -1)">审核 </Button>
                    </template>
                </Table>
            </div>
        </div>
        <div class="block" v-if="mP.bShow">
            <div class="title">
                商业项目
            </div>
            <div style="margin-top: 20px">
                <Table border :loading=mP.isLoading :columns="mP.tbColumn" :data="mP.tbData">
                    <template slot-scope="{index}" slot="index">
                        <span>{{index+1}}</span>
                    </template>
                    <template slot-scope="{row}" slot="action">
                        <Button type="info" size="small" @click="action(row, true)">详情 ...</Button>
                    </template>
                </Table>
            </div>
        </div>
        <div class="block" v-if="mP2.bShow">
            <div class="title">
                相关商业项目
            </div>
            <div style="margin-top: 20px">
                <Table border :loading=mP2.isLoading :columns="mP2.tbColumn" :data="mP2.tbData">
                    <template slot-scope="{index}" slot="index">
                        <span>{{index+1}}</span>
                    </template>
                    <template slot-scope="{row}" slot="action">
                        <Button type="info" size="small" @click="action(row, true)">详情 ...</Button>
                    </template>
                </Table>
            </div>
        </div>
        <div class="block" v-if="mV.bShow">
            <div class="title">
                短视频
            </div>
            <div style="margin-top: 20px">
                <Table border :loading=mV.isLoading :columns="mV.tbColumn" :data="mV.tbData" @on-row-click="order"
                       style="cursor: pointer">
                    <template slot-scope="{index}" slot="index">
                        <span>{{index+1}}</span>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func, g_user} from "../source";

    export default {
        name: "MyWork",
        components: {PageHeader},
        data() {
            return {
                mC: {
                    isLoading: false,
                    tbColumn: [
                        {
                            title: "序号",
                            slot: "index",
                            align: "center"
                        },
                        {
                            title: "审核类型",
                            key: "type",
                            align: "center"
                        },
                        {
                            title: "短视频名称",
                            key: "video",
                            align: "center"
                        },
                        {
                            title: "编导",
                            key: "director",
                            align: "center"
                        },
                        {
                            title: "所属项目",
                            key: "project",
                            align: "center"
                        },
                        {
                            title: "项目经理",
                            key: "manager",
                            align: "center"
                        },
                        {
                            title: "创建时间",
                            key: "createTime",
                            align: "center"
                        },
                        {
                            title: "审核",
                            slot: "action",
                            align: "center",
                        }
                    ],
                    tbData: [],
                    bShow: g_user.isMusicConfirm() || g_user.isAdmin()
                },
                mV: {
                    bShow:g_user.isAdmin() || g_user.getCareerId() === g_user.careerDirectorId || g_user.getCareerId() === g_user.careerProducerId || g_user.getCareerId() === g_user.careerRecorderId || g_user.getCareerId() === g_user.careerMusicMixerId,
                    isLoading: false,
                    tbColumn: [
                        {
                            title: "序号",
                            slot: "index",
                            align: "center"
                        },
                        {
                            title: "短视频名称",
                            key: "videoName",
                            align: "center"
                        },
                        {
                            title: "所属项目",
                            key: "projectName",
                            align: "center"
                        },
                        {
                            title: "事项",
                            key: "type",
                            align: "center"
                        },
                        {
                            title: "编导",
                            key: "director",
                            align: "center"
                        },
                        {
                            title: "达人",
                            key: "star",
                            align: "center"
                        },
                        {
                            title: "创建时间",
                            key: "createTime",
                            align: "center"
                        },
                    ],
                    tbData: [],
                },
                mP: {
                    isLoading: false,
                    tbColumn: [
                        {
                            title: "序号",
                            slot: "index",
                            align: "center"
                        },
                        {
                            title: "项目名称",
                            key: "name",
                            align: "center"
                        },
                        {
                            title: "类型",
                            key: "type",
                            align: "center"
                        },
                        {
                            title: "状态",
                            key: "stat",
                            align: "center"
                        },
                        {
                            title: "项目经理",
                            key: "manager",
                            align: "center"
                        },
                        {
                            title: "开始时间",
                            key: "createTime",
                            align: "center"
                        },
                        {
                            title: "上线时间",
                            key: "onlineTime",
                            align: "center"
                        },
                        {
                            title: "操作",
                            slot: "action",
                            align: "center"
                        },
                    ],
                    tbData: [],
                    bShow: g_user.isProjectManager() || g_user.isAdmin() || g_user.getCareerId() === g_user.careerBusinessId
                },
                mP2: {
                    isLoading: false,
                    tbColumn: [
                        {
                            title: "序号",
                            slot: "index",
                            align: "center"
                        },
                        {
                            title: "项目名称",
                            key: "name",
                            align: "center"
                        },
                        {
                            title: "类型",
                            key: "type",
                            align: "center"
                        },
                        {
                            title: "状态",
                            key: "stat",
                            align: "center"
                        },
                        {
                            title: "项目经理",
                            key: "manager",
                            align: "center"
                        },
                        {
                            title: "开始时间",
                            key: "createTime",
                            align: "center"
                        },
                        {
                            title: "上线时间",
                            key: "onlineTime",
                            align: "center"
                        },
                        {
                            title: "操作",
                            slot: "action",
                            align: "center"
                        },
                    ],
                    tbData: [],
                    bShow: g_user.isDirector(),
                }
            }
        },
        created() {
            this.getMyWorkVideo();
            if (this.mC.bShow) {
                this.getMyWorkVerify();
            }
            if (this.mP.bShow) {
                this.getMyWorkProject();
            }
            if (this.mP2.bShow) {
                this.getDirectorProject();
            }
        },
        methods: {
            validName(t) {
                return t ? t : '-';
            },
            action(row, bProject) {
                if (bProject) {
                    if (!row.id) {
                        return
                    }
                    this.$router.push({path: 'projectAction', query: {projectId: row.id}})
                } else {
                    this.$router.push({path: 'musicOrderConfirm', query: {orderId: row.orderId}})
                }
            },
            getMyWorkVerify() {
                g_func.getMyWorkVerify(res => {
                    if (!g_func.checkResCode(this, res, `获取待审核信息失败`)) {
                        return 0;
                    }
                    res.msg.list.forEach(v => {
                        this.mC.tbData.push(
                            {
                                index: this.mC.tbData.length,
                                orderId: v.videoOrderId,
                                videoId: v.v.id,
                                id: v.p.id,
                                type: v.typeName,
                                video: this.validName(v.v.name),
                                director: this.validName(v.d.name),
                                project: this.validName(v.p.name),
                                manager: this.validName(v.m.name),
                                createTime: v.createTime.split(' ')[0]
                            }
                        );
                    });
                });
            },
            getMyWorkVideo() {
                this.mP2.isLoading = true;
                g_func.getMyWorkVideo(res => {
                    this.mP2.isLoading = false;
                    if (!g_func.checkResCode(this, res, `获取短视频需求单失败`)) {
                        return 0;
                    }
                    let type = (t) => {
                        switch (t) {
                            case 1:
                                return '脚本';
                            case 2:
                                return '编曲';
                            case 3:
                                return '录音';
                            case 4:
                                return '混音';
                            case 5:
                                return '拍摄';
                            default:
                                return '未知';
                        }
                    };

                    res.msg.data.forEach(v => {
                        v.index = this.mV.tbData.length;
                        v.type = type(v.orderType);
                        v.createTime = v.createTime.split(' ')[0];
                        this.mV.tbData.push(v);
                    })
                })
            },
            getMyWorkProject() {
                this.mP2.isLoading = true;
                g_func.getMyWorkProject(res => {
                    this.mP2.isLoading = false;
                    if (!g_func.checkResCode(this, res, `获取商业项目信息失败`)) {
                        return 0;
                    }
                    res.msg.list.forEach(v => {
                        this.mP.tbData.push({
                            index: this.mP.tbData.length,
                            id: v.id,
                            name: v.title,
                            type: v.typeName,
                            stat: v.stat,
                            manager: v.manager.name,
                            createTime: v.createTime.split(' ')[0],
                            onlineTime: v.onlineTime.split(' ')[0],
                        });
                    })
                })
            },
            order(row) {
                console.log(row);
                this.$router.push({path: 'orderInfo', query: {orderId: row.orderId}});
            },
            getDirectorProject() {
                this.mP2.isLoading = true;
                g_func.getDirectorProject(res => {
                    this.mP2.isLoading = false;
                    if (!g_func.checkResCode(this, res, `获取关联商业项目信息失败`)) {
                        return 0;
                    }
                    res.msg.data.forEach(v => {
                        this.mP2.tbData.push({
                            index: this.mP2.tbData.length,
                            id: v.id,
                            name: v.title,
                            type: v.typeName,
                            stat: v.stat,
                            manager: v.manager.name,
                            createTime: v.createTime.split(' ')[0],
                            onlineTime: v.onlineTime.split(' ')[0],
                        });
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .title {
        text-align: left;
        font-size: 20px;
        font-weight: 700;
    }

    .block {
        margin-bottom: 20px;
    }
</style>