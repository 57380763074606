<template>
    <div>
        <Layout class="layout">
            <Header class="header">
                <div class="header-title">发力人</div>
                <div class="header-logout">
                    <div>您好，{{mNick}}</div>
                    <div style="margin-left: 2vw"><a @click="logout()">退出</a></div>
                </div>
            </Header>
            <Layout style="min-height: 100%;">
                <Sider hide-trigger :style="{background: '#fff'}">
                    <Menu active-name="menu" theme="light" width="auto" class="menu-left">
                        <MenuItem class="MenuItem" name="1" @click.native="menu('MyWork')">
                            <Icon type="md-document"/>
                            我的工作
                        </MenuItem>
                        <MenuItem v-if="mMenuVideo" class="MenuItem" name="2" @click.native="menu('Videos')">
                            <Icon type="md-document"/>
                            短视频
                        </MenuItem>
                        <MenuItem v-if="mMenuProject" class="MenuItem" name="3" @click.native="menu('Projects')">
                            <Icon type="md-document"/>
                            商业项目
                        </MenuItem>
                        <MenuItem class="MenuItem" name="4" @click.native="menu('Studio', {showBtn:true})">
                            <Icon type="md-document"/>
                            录音棚排期
                        </MenuItem>
                        <MenuItem class="MenuItem" name="5" @click.native="menu('SourceStorage')">
                            <Icon type="md-document"/>
                            资源库
                        </MenuItem>
                        <MenuItem v-if="mMenuData" class="MenuItem" name="6" @click.native="menu('PlatformData')">
                            <Icon type="md-document"/>
                            数据统计
                        </MenuItem>
                        <MenuItem v-if="mMenuUser" class="MenuItem" name="7" @click.native="menu('Users')">
                            <Icon type="md-document"/>
                            人员管理
                        </MenuItem>
                    </Menu>
                </Sider>
                <Layout :style="{padding: '0 24px 24px'}">
                    <Content :style="{padding: '24px', minHeight: '280px', background: '#fff'}">
                        <router-view>
                        </router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    </div>
</template>

<script>
    import {g_user} from "../source";
    export default {
        name: "Index",
        data() {
            return {
                mNick: g_user.getNick(),
                mLogin: g_user.isLogin(),
                mMenuVideo: g_user.isAdmin() || g_user.getCareerId() === g_user.careerDirectorId,
                mMenuProject: g_user.isAdmin() || g_user.isProjectManager() || g_user.getCareerId() === g_user.careerBusinessId,
                mMenuData: g_user.isAdmin() || g_user.isDataRights(),
                mMenuUser: g_user.isAdmin(),
            }
        },
        methods: {
            logout() {
                this.$emit('logout');
            },
            menu(name, params) {
                this.$router.push({path: name, query: params});
            }
        },
    }
</script>

<style scoped>

    .header {
        color: white;
        display: flex;
        flex-direction: column;
        justify-items: baseline;
    }

    .header-title {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 18px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .header-logout {
        display: flex;
        position: absolute;
        right: 4vw;
    }

    .menu-left {
        height: 100%;
    }

    .layout {
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        border-radius: 4px;
        overflow: hidden;
        min-height: 800px;
    }

    .MenuItem {
        text-align: left;
    }
</style>