<template>
    <div>
        <page-header
                :title-txt="'创意库'"
                :btn2-txt="'添加创意'"
                @btn2Click="add"
        >
        </page-header>
        <Table style="margin-top: 20px" border :loading=mIsLoading :columns="mTbTitle" :data="mTbData">
            <template slot-scope="{row}" slot="play">
                <a v-if="showPlay(row.path)" @click="play(row)">{{row.name}}</a>
                <span v-if="!showPlay(row.path)">{{row.name}}-1</span>
            </template>
            <template slot-scope="{row}" slot="download">
                <a :href="row.path" target="_blank">下载</a>
            </template>
        </Table>
        <div class="page">
            <Page v-if="mTotalPage >= 20" :total="mTotalPage" :current.sync="mCurrentPage" @on-change="search"/>
        </div>
        <Modal
                v-model="mModalPlay"
                :closable="false"
                :footer-hide="true"
                @on-cancel="cancel"
        >
            <video style="width: 100%; height: 100%"
                   :src="mPlayPath"
                   controls="controls" autoplay loop/>
        </Modal>
    </div>
</template>

<script>
    import {g_func} from "../source";
    import PageHeader from "./PageHeader";

    export default {
        name: "ResourceIdea",
        components: {PageHeader},
        data() {
            return {
                mCurrentPage: 1,
                mTotalPage: 0,
                mPlayPath: '',
                mModalPlay: false,
                mTbTitle: [
                    {
                        title: '序号',
                        key: 'index',
                        align: 'center',
                    },
                    {
                        title: '名称',
                        slot: 'play',
                        align: 'center',
                    },
                    {
                        title: '上传者',
                        key: 'uploaderName',
                        align: 'center',
                    },
                    {
                        title: '上传时间',
                        key: 'uploadTime',
                        align: 'center',
                    },
                    {
                        title: '链接',
                        slot: 'download',
                        align: 'center',
                    },
                ],
                mTbData: [],
                mIsLoading: false,
            }
        },
        created() {
            this.search(0);
        },
        methods: {
            add() {
                this.$router.push({path:'/addIdea'});
            },
            search(n) {
                this.mCurrentPage = n;
                let page = n <= 0 ? 0 : n - 1;
                let pageCount = 10;
                this.mIsLoading = true;
                this.mTbData = [];
                this.mCurrentPage = 1;
                g_func.getResourceIdea(page * pageCount, pageCount, (res) => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `获取创意库失败`)) {
                        return 0;
                    }
                    this.mTotalPage = Math.ceil(res.msg.total / pageCount) * 10;
                    res.msg.data.forEach((v, k) => {
                        v.index = k + 1;
                        v.uploaderName = v.uploader.name;
                        this.mTbData.push(v);
                    });
                });
            },
            play(r) {
                this.mPlayPath = r.path;
                this.mModalPlay = true;
            },
            cancel() {
                this.mPlayPath = '';
            },
            showPlay(path) {
                return path.match('/.mp3$|.mp4$|.mov$/i')
            }
        }
    }
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>