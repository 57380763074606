import { render, staticRenderFns } from "./AddOrder.vue?vue&type=template&id=68ca86e2&scoped=true&"
import script from "./AddOrder.vue?vue&type=script&lang=js&"
export * from "./AddOrder.vue?vue&type=script&lang=js&"
import style0 from "../css/order.css?vue&type=style&index=0&id=68ca86e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ca86e2",
  null
  
)

export default component.exports