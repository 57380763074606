<template>
    <div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title" style="width: 100px">{{this.mUserTitle}}：</span>
            <Select placeholder="请选择" v-model="mSelectId">
                <Option v-for="item in mRecorder" :value="item.id" :key="item.id">{{item.name}}
                </Option>
            </Select>
        </div>
        <div class="item-space title">
            {{this.mUserTitle}}当前工作：
        </div>
        <div class="item-space sc">
            <div class="tb">
            <div v-for="(v, i) in mTbData" :key="`x${i}`" class="tbRow">
                <div v-for="(vv, ii) in v" :key="`y${ii}`" :class="ii === 0 ? 'tbBlock0' : i % 2 ? 'tbBlock1' : 'tbBlock2'">
                    {{vv}}
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {g_func} from "../source";

    export default {
        name: "UserWorkList",
        props: ['careerId'],
        data() {
            return {
                mSelectId: 0,
                mCareerId: this.careerId,
                mRecorder: [],
                mUserTitle: '',
                mTbData: [new Array(0)],
                mIsLoading: false,
                mTbColumn: [
                    {
                        title:'d1',
                        key: 'd1',
                        fixed: 'left',
                    },
                    {
                        title:'d2',
                        key: 'd2',
                    },
                    {
                        title:'d3',
                        key: 'd3',
                    },
                    {
                        title:'d4',
                        key: 'd4',
                    },
                    {
                        title:'d5',
                        key: 'd5',
                    },
                ]
            }
        },
        created() {
            this.mUserTitle = g_func.careerId2Title(this.mCareerId);
            if (!this.mUserTitle) {
                g_func.errParam(this, '获取工作列表失败');
                this.$router.go(-1);
            }
            this.getRecorders();
            this.getCareerWorks();
        },
        methods: {
            getRecorders() {
                g_func.getUsers(this.mCareerId, (res) => {
                    if (!g_func.checkResCode(this, res, '获取录音师列表失败')) {
                        this.$router.go(-1);
                        return;
                    }
                    res.msg.users.forEach((v) => {
                        this.mRecorder.push({
                            id: v.id,
                            name: v.n,
                        });
                    });
                });
            },
            getCareerWorks() {
                g_func.getCareerWorkList(this.mCareerId, res => {
                    if (!g_func.checkResCode(this, res, '获取工作列表失败')) {
                        this.$router.go(-1);
                        return;
                    }
                    if (res.msg.list.length === 0) {
                        return;
                    }
                    let tbData = {};
                    res.msg.list.forEach(v => {
                        if (!tbData[v.user.name]) {
                            tbData[v.user.name] = new Array(0);
                            tbData[v.user.name].push(v.user.name);
                        }
                        tbData[v.user.name].push(v.video.title);
                    });

                    for (let i in tbData) {
                        this.mTbData.push(tbData[i]);
                    }
                })
            },
        }
    }
</script>

<style scoped src="../css/order.css" />
<style>
    .sc {
        margin-top: 20px;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    .tb {
        display: inline-table;
        width: 900px;
    }
    .tbRow {
        display: flex;
    }
    .tbBlock1, .tbBlock2, .tbBlock0 {
        display: flex;
        width: 110px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color: rgba(225, 248, 255, 0.8);
        text-align: center;
        margin: 2px;
        border-radius: 4px;
        font-size: 8px;
    }
    .tbBlock2 {
        background-color: rgba(252, 248, 227, 0.8);
    }
    .tbBlock0 {
        background-color: rgba(198, 219, 242, 1);
        font-weight: 900;
        font-size: 14px;
    }
</style>