<template>
    <div>
        <div class="dataLine">
            <span class="dataData">进行中: {{mDoing}}</span>
            <span class="dataData">昨天上线: {{mYesterday}}</span>
        </div>
        <div class="dataLine">
            <span class="dataData">本周上线: {{mThisWeek}}</span>
            <span class="dataData">本月上线: {{mThisMonth}}</span>
        </div>
        <div style="display: flex; flex-direction: row; align-items: baseline; margin-top: 20px">
            <DatePicker style="width: 400px" type="datetimerange" placeholder="选择查询时间" format="yyyy年M月d日 HH:mm"
                        v-model="mTime"
                        :editable="false"/>
            <Select style="width: 100px; margin-left: 20px;" placeholder="全部状态" v-model="mStat">
                <Option v-for="it in mOptionStat" :key="it.id" :value="it.id">{{it.name}}</Option>
            </Select>
            <Button type="primary" style="margin-left: 20px" @click="search">查询</Button>
        </div>
        <Table style="margin-top: 20px" border :loading=mIsLoading :columns="mTbTitle" :data="mTbData"
               :row-class-name="rowClass" @on-row-click="projectInfo">
            <template slot-scope="{row}" slot="action">
                <Button v-if="row.path" type="text" @click="play(row)">播放</Button>
            </template>
        </Table>
        <Modal
                v-model="mModalPlay"
                :closable="false"
                :footer-hide="true"
                @on-cancel="cancel"
        >
            <video style="width: 100%; height: 100%"
                   :src="mPlayPath"
                   controls="controls" autoplay loop/>
        </Modal>
    </div>
</template>

<style>
    .platform-table {
        cursor: pointer;
    }
</style>

<script>
    import {g_func} from "../source";

    export default {
        name: "PlatformDataWork",
        props: ['id'],
        data() {
            return {
                mBegin: '',
                mEnd: '',
                mDoing: 0,
                mYesterday: 0,
                mThisWeek: 0,
                mThisMonth: 0,
                mStat: 0,
                mTbTitle: [],
                mTbData: [],
                mOptionStat: [],
                mIsLoading: false,
                mTime: [(g_func.getDatetime(new Date((new Date()).getTime() - 1000 * 3600 * 24 * 7))), g_func.getDatetime(new Date())],
                mPlayPath: '',
                mModalPlay: false,
            }
        },
        methods: {
            projectInfo(r) {
                if (this.id === 1) {
                    return
                }
                this.$router.push({path: 'projectAction', query: {projectId: r.id}});
            },
            rowClass() {
                if (this.id === 2) {
                    return 'platform-table'
                }
                return '';
            },
            play(r) {
                this.mPlayPath = r.path;
                this.mModalPlay = true;
            },
            cancel() {
                this.mPlayPath = '';
            },
            changeTable() {
                this.mTbData = [];
                if (this.id === 1) {
                    this.mTbTitle = [
                        {
                            title: '序号',
                            key: 'index',
                            align: 'center',
                        },
                        {
                            title: '视频名称',
                            key: 'name',
                            align: 'center',
                        },
                        {
                            title: '编导',
                            key: 'directors',
                            align: 'center',
                        },
                        {
                            title: '达人',
                            key: 'stars',
                            align: 'center',
                        },
                        {
                            title: '状态',
                            key: 'statName',
                            align: 'center',
                        },
                        {
                            title: '创建时间',
                            key: 'createTime',
                            align: 'center',
                        },
                        {
                            title: '上线时间',
                            key: 'onlineTime',
                            align: 'center',
                        },
                        {
                            title: '播放',
                            slot: 'action',
                            align: 'center',
                        },
                    ];
                    this.mOptionStat = [{id: 0, name: '全部状态'}, {id: 1, name: '未上线'}, {id: 2, name: '已上线'},];
                } else {
                    this.mTbTitle = [
                        {
                            title: '序号',
                            key: 'index',
                            align: 'center',
                        },
                        {
                            title: '项目名称',
                            key: 'name',
                            align: 'center',
                        },
                        {
                            title: '项目类型',
                            key: 'type',
                            align: 'center',
                        },
                        {
                            title: '项目经理',
                            key: 'managerName',
                            align: 'center',
                        },
                        {
                            title: '项目状态',
                            key: 'statName',
                            align: 'center',
                        },
                    ];
                    this.mOptionStat = [{id: 0, name: '全部状态'}, {id: 1, name: '立项审核'}, {id: 2, name: '已立项'}, {
                        id: 4,
                        name: '已验收'
                    }, {id: -1, name: '被拒绝'},];
                }
            },
            getData() {
                g_func.getDataCount(this.id, res => {
                    if (!g_func.checkResCode(this, res, '获取数据失败')) {
                        return;
                    }
                    this.mDoing = res.msg.doing;
                    this.mYesterday = res.msg.yesterday;
                    this.mThisWeek = res.msg.week;
                    this.mThisMonth = res.msg.month;
                });
            },
            search() {
                if (this.mTime.length < 2 || !this.mTime[0] || !this.mTime[1]) {
                    g_func.errParam(this, '请选择查询时间');
                    return;
                }
                let time = `${g_func.getDatetime(this.mTime[0])}~${g_func.getDatetime(this.mTime[1])}`;
                this.mTbData = [];
                g_func.getDataTable(this.id, this.mStat, time, res => {
                    res.msg.data.forEach((v, k) => {
                        v.index = k + 1;
                        if (this.id === 2) {
                            v.managerName = v.manager.name;
                        }
                        this.mTbData.push(v)
                    });
                });
            },
            init() {
                this.changeTable();
                this.getData();
            }
        },
        created() {
            this.init()
        },
        watch: {
            id() {
                this.init();
            }
        }
    }
</script>

<style scoped>

    .dataLine {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px;
    }

    .dataData {
        width: 400px;
    }

    .date {
        margin-top: 10px;
        text-align: left;
    }
</style>