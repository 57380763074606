<template>
    <div>
        <page-header
                :title-txt="'商业项目'"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
            <label>
                <Input search enter-button="查询" v-model="mSearchTxt" placeholder="搜索项目名称、合作方、项目经理"
                       @on-search="search(1)"
                       style="width: 300px"/>
            </label>
            <div>
                <Button type="primary" style="width: 140px" @click="addProject">申请立项</Button>
            </div>
        </div>
        <div style="margin-top: 20px">
            <Table border :loading=mIsLoading :columns="mTbColumn" :data="mTbData">
                <template slot-scope="{index}" slot="index">
                    <span>{{index+1}}</span>
                </template>
                <template slot-scope="{row}" slot="action">
                    <Button type="info" size="small" style="margin-right: 5px" @click="project(row)">详情</Button>
                    <Button type="error" size="small" @click="delProject(row)">删除</Button>
                </template>
            </Table>
            <div class="page">
                <Page v-if="mTotalPage >= 20" :total="mTotalPage" :current.sync="currentPage" @on-change="search"/>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";

    export default {
        name: "Projects",
        components: {PageHeader},
        data() {
            return {
                mSearchTxt: '',
                mIsLoading: false,
                mTbColumn: [
                    {
                        title: "序号",
                        slot: "index",
                        align: "center"
                    },
                    {
                        title: "项目名称",
                        key: "name",
                        align: "center"
                    },
                    {
                        title: "项目类型",
                        key: "type",
                        align: "center"
                    },
                    {
                        title: "合作方",
                        key: "company",
                        align: "center"
                    },
                    {
                        title: "项目经理",
                        key: "manager",
                        align: "center"
                    },
                    {
                        title: "立项时间",
                        key: "agreeTime",
                        align: "center"
                    },
                    {
                        title: "状态",
                        key: "stat",
                        align: "center"
                    },
                    {
                        title: "上线时间",
                        key: "onlineTime",
                        align: "center"
                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center",
                        width: 150,
                    },
                ],
                mTbData: [],
                mTotalPage: 0,
                currentPage: 0,
            }
        },
        created() {
            this.search(1);
        },
        methods: {
            project(r) {
                this.$router.push({path: 'projectAction', query: {projectId: r.id}})
            },
            addProject() {
                this.$router.push({path: 'addProject'});
            },
            delProject(row) {
                this.$Modal.confirm({
                        title: `确认删除项目《${row.name}》？`,
                        content: `删除项目后，项目下的短视频将不再关联`,
                        onOk: () => {
                            this.delProjectOk(row.id)
                        }
                    }
                )
            },
            delProjectOk(id) {
                g_func.deleteProject(id, res => {
                    if (!g_func.checkResCode(this, res, '删除项目失败')) {
                        return 0;
                    }
                    this.mTbData = this.mTbData.filter((v) => {
                        return v.id !== id;
                    });
                    g_func.doOk(this)
                })
            }
            ,
            search(n) {
                this.currentPage = n;
                let page = n <= 0 ? 0 : n - 1;
                let pageCount = 10;
                this.mIsLoading = true;
                this.mTbData = [];
                g_func.getProject(this.mSearchTxt, 0, page * pageCount, pageCount, (res) => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `查询商业项目失败`)) {
                        return 0;
                    }
                    this.mTotalPage = Math.ceil(res.msg.total / pageCount) * 10;
                    res.msg.projects.forEach(vv => {
                        this.mTbData.push(
                            {
                                id: vv.id,
                                index: vv.length,
                                name: vv.name,
                                type: vv.type,
                                company: vv.company,
                                manager: vv.manager.name,
                                agreeTime: vv.agreeTime ? vv.agreeTime.split(' ')[0] : '-',
                                stat: vv.stat,
                                onlineTime: vv.onlineTime.split(' ')[0],
                            }
                        );
                    });
                    this.$Message['info']({
                        background: true,
                        content: `查询完成: 共${res.msg.total ? res.msg.total : 0}个结果`,
                        duration: 2,
                    });
                });
            }
        },
    }
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>