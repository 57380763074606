<template>
    <div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">达人：</span>
            <Input class="add-video-input-select" v-model="mStar" :readonly="true"/>
        </div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">风格：</span>
            <Input class="add-video-input-select" v-model="mStyle"/>
        </div>
        <user-work-list
                :career-id="5"
                ref="uwl"
        >
        </user-work-list>
        <div class="add-video-input item-space desc">
            <span class="add-video-input-title title">混音说明：</span>
            <label class="add-video-input-select">
                <Input type="textarea" :rows="10" v-model="mDescription"/>
            </label>
        </div>
    </div>
</template>

<script>
    import {g_func, g_user} from "../source";
    import UserWorkList from "./UserWorkList";

    export default {
        name: "AddOrderMix",
        components: {UserWorkList},
        props: ['video'],
        data() {
            return {
                mStar: '',
                mMixer: [],
                mStyle: '',
                mDescription:'',
            }
        },
        watch: {
            video() {
                this.videoChange();
            }
        },
        created() {
            this.videoChange();

            g_func.getUsers(g_user.careerMusicMixerId, (res) => {
                if (!g_func.checkResCode(this, res, '获取制作人列表失败')) {
                    this.$router.go(-1);
                    return;
                }
                res.msg.users.forEach((v) => {
                    this.mMixer.push({
                        id: v.id,
                        name: v.n,
                    });
                });
            })
        },
        methods: {
            videoChange() {
                if (!this.video || !this.video.id) {
                    return 0;
                }
                let stars = [];
                this.video.stars.forEach((v) => {
                    stars.push(v.s.name);
                });
                this.mStar = stars.join('，');
            }
        }
    }
</script>

<style scoped src="../css/order.css">

</style>