<template>
    <div>
        <page-header
                :title-txt="'数据统计'"
        >
        </page-header>
        <div>
            <Tabs type="card" :animated="false" @on-click="select">
                <TabPane v-for="it in mTab" :key="it.id" :label="it.name">
                </TabPane>
            </Tabs>
            <PlatformDataWork v-if="mSelectId === 1 || mSelectId === 2"
                              :id="mSelectId"
            >
            </PlatformDataWork>
            <platform-data-user v-if="mSelectId === 3" />
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import PlatformDataWork from "./PlatformDataWork";
    import PlatformDataUser from "./PlatformDataUser";
    import {g_user} from "../source";

    export default {
        name: "PlatformData",
        components: {PlatformDataUser, PlatformDataWork, PageHeader},
        data() {
            return {
                mTab: [
                    {
                        id: 1,
                        name: '短视频'
                    },
                    {
                        id: 2,
                        name: '商业项目'
                    },
                    {
                        id: 3,
                        name: '人员数据'
                    },
                ],
                mSelectId: 0,
            }
        },
        created() {
            this.mTab = [];
            if (g_user.isDataWorkRights()) {
                this.mTab.push({
                    id: 1,
                    name: '短视频'
                });
                this.mTab.push({
                    id: 2,
                    name: '商业项目'
                });
                this.select(0);
            }
            if (g_user.isDataUserRights()) {
                this.mTab.push(
                    {
                        id: 3,
                        name: '人员数据'
                    }
                );
                if (!g_user.isDataWorkRights()) {
                    this.select(2);
                }
            }
        },
        methods: {
            select(n) {
                this.mSelectId = n + 1;
            }
        }
    }
</script>

<style src="../css/order.css">
</style>

<style scoped>
    .dataTitle {
    }

</style>