<template>
    <div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">达人：</span>
            <Input class="add-video-input-select" v-model="mStar" :readonly="true"/>
        </div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">编导：</span>
            <Select class="add-video-input-select" placeholder="请选择" v-model="mSelectDirectorId">
                <Option v-for="item in mDirectors" :value="item.id" :key="item.id">{{item.name}}
                </Option>
            </Select>
        </div>
        <div class="add-video-input item-space desc">
            <span class="add-video-input-title title">脚本说明：</span>
            <label class="add-video-input-select">
                <Input type="textarea" :rows="10" v-model="mDescription"/>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddOrderScript",
        props: ['video'],
        data() {
            return {
                mStar: '',
                mSelectDirectorId: 0,
                mDescription: '',
                mDirectors: [],
            }
        },
        watch: {
            video() {
                this.videoChange();
            }
        },
        created() {
            this.videoChange();
        },
        methods: {
            videoChange() {
                if (!this.video || !this.video.id) {
                    return 0;
                }
                let stars = [];
                this.mDirectors = [];
                this.video.stars.forEach((v) => {
                    if (this.mDirectors.findIndex((vv) => {
                        return vv.id === v.d.id
                    }) === -1) {
                        this.mDirectors.push({
                            id: v.d.id,
                            name: v.d.name,
                        });
                    }
                    stars.push(v.s.name);
                });
                this.mStar = stars.join('，');
            }
        }
    }
</script>

<style scoped src="../css/order.css">
</style>