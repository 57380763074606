<template>
    <div>
        <div class="add-video-input item-space" v-for="it in mSelectStars" :key="`div${it.index}`">
            <span class="add-video-input-title title">达人：</span>
            <Select class="add-video-input-select" placeholder="请选择" v-model="it.select"
                    @on-change="(n)=> {it.selectId=n.tag;}" label-in-value>
                <Option v-for="item in mStars" :value="item.name" :key="item.id" :tag="item.id">{{item.name}}
                </Option>
            </Select>
            <div class="item-delete" @click="delItem(it.selectId)">
                <Icon type="ios-remove-circle-outline" size="20" color="red"/>
            </div>
        </div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">时间：</span>
            <DatePicker class="add-video-input-select" type="datetime" placeholder="选择拍摄时间" style="width: 200px" v-model="mShootTime"/>
        </div>
        <div class="add-video-input item-space">
            <span class="add-video-input-title title">地点：</span>
            <Input class="add-video-input-select" v-model="mPlace"/>
        </div>
        <div class="add-video-input item-space desc">
            <span class="add-video-input-title title">拍摄说明：</span>
            <label class="add-video-input-select">
                <Input type="textarea" :rows="10" v-model="mDescription"/>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddOrderShoot",
        props: ['video'],
        data() {
            return {
                mStars: [],
                mSelectStars: [],
                mPlace: '',
                mDescription: '',
                mShootTime: '',
            }
        },
        watch: {
            video() {
                this.videoChange();
            }
        },
        created() {
            this.videoChange();
        },
        methods: {
            delItem(id) {
                this.mSelectStars = this.mSelectStars.filter((v) => {
                    return v.selectId !== id;
                })
            },
            videoChange() {
                if (!this.video || !this.video.id) {
                    return 0;
                }
                this.mStars = [];
                this.mSelectStars = [];
                this.video.stars.forEach((v) => {
                    this.mStars.push({
                        id: v.s.id,
                        name: v.s.name,
                    });
                    this.mSelectStars.push({
                        index: this.mSelectStars.length,
                        select: v.s.name,
                        selectId: v.s.id,
                    })
                });
            },
        }
    }
</script>

<style scoped src="../css/order.css">
</style>