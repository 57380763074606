<template>
    <div>
        <page-header
                :title-txt="'资源库'"
        >
        </page-header>
        <div class="line">
            <Button class="box-2" @click="idea">创意库</Button>
            <Button class="box-1" @click="resource(1)">脚本库</Button>
            <Button class="box-1" @click="resource(2)">编曲库</Button>
        </div>
        <div class="line">
            <Button class="box-2" @click="resource(10)">终版视频库</Button>
            <Button class="box-1" @click="resource(3)">干音库</Button>
            <Button class="box-1" @click="resource(4)">混音库</Button>
            <Button class="box-1" @click="resource(5)">拍摄视频素材库</Button>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";

    export default {
        name: "ResourceSelect",
        components: {PageHeader},
        data() {
            return {}
        },
        methods: {
            resource(type) {
                this.$router.push({path: 'resource', query: {type: type}});
            },
            idea() {
                this.$router.push({path: 'resourceIdea'});
            }
        }
    }
</script>

<style scoped>
    .line {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .box-1, .box-2 {
        width: 200px;
        height: 150px;
        margin: 10px;
        border-radius: 20px;
        background-color: #ccc;
        font-size: 20px;
    }

    .box-2 {
        width: 400px;
    }
</style>