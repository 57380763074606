<template>
    <div>
        <page-header
                :title-txt="txt() + '达人'"
        >
        </page-header>
        <div style="display: flex;flex-direction: row;justify-content: center">
            <div class="add-user-border">
                <label class="add-user-search-input">
                    <Input placeholder="姓名" v-model="mName"/>
                </label>
                <label class="add-user-search-input">
                    <Select style="text-align: left" placeholder="所属编导" v-model="mDirectorId">
                        <Option v-for="item in mDirectorList" :value="item.id" :key="item.id">{{item.n}}</Option>
                    </Select>
                </label>
                <label class="add-user-search-input">
                    <Input placeholder="电话" v-model="mPhone" :disabled="!!this.id"/>
                    <Button type="primary" :loading="mLoading" @click="addStar" class="add-user-search-input"
                            style="margin-top: 20px">{{txt()}}
                    </Button>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func, g_user} from "../source";

    export default {
        name: "AddStar",
        components: {PageHeader},
        props: ['id'],
        data() {
            return {
                mDirectorId: 0,
                mName: this.n,
                mPhone: this.p,
                mLoading: false,
                mDirectorList: [],
            }
        },
        created() {
            if (this.id) {
                g_func.getStar(this.id, res => {
                    this.mName = res.msg.star.name;
                    this.mPhone = res.msg.star.phone;
                    this.mDirectorId = res.msg.director.id;
                });
            }
            g_func.getUsers(g_user.careerDirectorId, (res) => {
                if (!g_func.checkResCode(this, res, '获取编导列表失败')) {
                    return;
                }
                this.mDirectorList = res.msg.users;
            });
        },
        methods: {
            txt() {
                return this.id ? '编辑' : '添加'
            },
            addStar() {
                let arg = {
                    id: this.id,
                    dirId: this.mDirectorId,
                    name: this.mName,
                    phone: this.mPhone,
                };
                if (!arg.name || !arg.dirId || !arg.phone) {
                    g_func.errParam(this, '姓名、所属编导、电话不能为空');
                    return 0
                }
                let finish = (res) => {
                    this.mLoading = false;
                    if (!g_func.checkResCode(this, res, `添加失败`)) {
                        return 0
                    }
                    g_func.doOk(this);
                    this.$router.go(-1);
                };
                this.mLoading = true;
                if (!arg.id) {
                    g_func.addStar(arg.name, arg.phone, arg.dirId, (res) => {
                        finish(res);
                    });
                } else {
                    g_func.editStar(arg.id, arg.name, arg.dirId, (res) => {
                        finish(res);
                    });
                }
            }
        },
    }
</script>

<style scoped>

    .add-user-border {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .add-user-search-input {
        width: 100%;
        margin-top: 10px;
    }

    .add-user-l {
        margin-top: 4px;
    }
</style>