<template>
    <div>
        <page-header
                :title-txt="mTitle"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: center">
            <div style="width: 900px; text-align: left">
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">关联短视频：</span>
                    <Input class="add-video-input-select" v-model="mVideo.title" v-if="videoId" :readonly="true"/>
                    <Select v-if="!videoId" class="add-video-input-select" placeholder="请选择"
                            @on-change="selectVideo">
                        <Option v-for="it in mVideos" :value="it.id" :key="it.id">{{it.name}}
                        </Option>
                    </Select>
                </div>
                <add-order-script
                        v-if="type == 1"
                        ref="script"
                        :video="mVideo"
                >
                </add-order-script>
                <add-order-music
                        v-if="type == 2"
                        ref="music"
                        :video="mVideo"
                >
                </add-order-music>
                <add-order-record
                        v-if="type == 3"
                        ref="record"
                        :video="mVideo"
                >
                </add-order-record>
                <add-order-mix
                        v-if="type == 4"
                        ref="mix"
                        :video="mVideo"
                >
                </add-order-mix>
                <add-order-shoot
                        v-if="type == 5"
                        ref="shoot"
                        :video="mVideo"
                >
                </add-order-shoot>
                <div class="item-space">
                    <Button :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                            @click="submit">提交《{{mTypeName}}》
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";
    import AddOrderScript from "./AddOrderScript";
    import AddOrderMusic from "./AddOrderMusic";
    import AddOrderRecord from "./AddOrderRecord";
    import AddOrderMix from "./AddOrderMix";
    import AddOrderShoot from "./AddOrderShoot";

    export default {
        name: "addOrder",
        components: {AddOrderShoot, AddOrderMix, AddOrderRecord, AddOrderMusic, AddOrderScript, PageHeader},
        props: ['type', 'videoId'],
        data() {
            return {
                mTitle: '需求单',
                mVideos: [],
                mVideo: {
                    title:'',
                },
                mIsLoading: false,
                mTypeName: '',
                mVideoStars: '',
                mVideoDirectors: [],
            }
        },
        created() {
            if (!this.type) {
                g_func.errParam(this, '');
                this.$router.push({path: 'videos'});
                return
            }
            this.mTypeName = g_func.getScript(this.type).name;
            this.mTitle = `《${this.mTypeName}》`;

            if (this.videoId) {
                g_func.getVideoById(this.videoId, res => {
                    this.mVideo = res.msg;
                });
            } else {
                g_func.getVideos('', 0, 10000, (res) => {
                    if (!g_func.checkResCode(this, res, `获取短视频信息失败`)) {
                        return 0;
                    }
                    res.msg.videos.forEach((v) => {
                        this.mVideos.push(
                            {
                                id: v.id,
                                name: v.name,
                            }
                        );
                    });
                });
            }
        },
        methods: {
            selectVideo(id) {
                this.mIsLoading = true;
                g_func.getVideoById(id, (res) => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `获取短视频信息失败`, () => {
                        this.$router.push({path:'videos'});
                        return 0;
                    })) {
                        return;
                    }
                    this.mVideo = res.msg;
                })
            },
            submit() {
                let data = this.getOrderData();
                if (!data) {
                    return
                }
                g_func.createOrder(this.type, data, res => {
                    if (!g_func.checkResCode(this, res, `创建${this.mTypeName}失败`)) {
                        return 0;
                    }
                    g_func.info(this, '操作成功', '创建需求单成功');
                    this.$router.push({path: 'videoInfo', query: {videoId: this.mVideo.id}});
                });
            },
            getOrderData() {
                if (!this.mVideo) {
                    g_func.errParam(this, '请关联短视频');
                    return
                }
                switch (this.type) {
                    case 1:
                        return this.getScriptOrderData();
                    case 2:
                        return this.getMusicOrderData();
                    case 3:
                        return this.getRecordOrderData();
                    case 4:
                        return this.getMixerOrderData();
                    case 5:
                        return this.getShootOrderData();
                    default:
                        this.$Modal.error({title: '参数异常'});
                        return;
                }
            },
            getScriptOrderData() {
                let did = this.$refs['script'].mSelectDirectorId;
                if (!did) {
                    g_func.errParam(this, `请选择编导`);
                    return
                }
                return {
                    vid: this.mVideo.id,
                    did: did,
                    desc: this.$refs['script'].mDescription,
                };
            },
            getMusicOrderData() {
                let p = this.$refs['music'];
                if (!p.mSelectProducerId) {
                    g_func.errParam(this, `请选择制作人`);
                    return
                }
                return {
                    vid: this.mVideo.id,
                    pid: p.mSelectProducerId,
                    style: p.mStyle,
                    ref: p.mReference,
                    desc: p.mDescription,
                }
            },
            getRecordOrderData() {
                let p = this.$refs['record'];
                let st = p.$refs['studio'];
                let uwl = p.$refs['uwl'];
                let stars = [];
                p.mSelectStars.forEach((v) => {
                    stars.push(v.selectId);
                });
                if (!p.mSelectStars.length) {
                    g_func.errParam(this, `请选择达人`);
                    return
                }
                if (!uwl.mSelectId) {
                    g_func.errParam(this, `请选择录音师`);
                    return;
                }
                let data = st.getSelectStudio();
                return {
                    vid: this.mVideo.id,
                    stars: stars,
                    studio: data.studio,
                    begin: data.begin,
                    end: data.end,
                    rid: uwl.mSelectId,
                }
            },
            getMixerOrderData() {
                let p = this.$refs['mix'];
                let uwl = p.$refs['uwl'];
                if (!uwl.mSelectId) {
                    g_func.errParam(this, `请选择混音师`);
                    return;
                }
                return {
                    vid: this.mVideo.id,
                    style: p.mStyle,
                    mid: uwl.mSelectId,
                    desc: p.mDescription,
                }
            },
            getShootOrderData() {
                let p = this.$refs['shoot'];
                let stars = [];
                p.mSelectStars.forEach((v) => {
                    stars.push(v.selectId);
                });
                if (!p.mSelectStars.length) {
                    g_func.errParam(this, `请选择达人`);
                    return
                }
                if (!p.mShootTime) {
                    g_func.errParam(this, `请选择拍摄时间`);
                    return
                }
                let shootTime = g_func.getDatetime(p.mShootTime);
                if (!p.mPlace) {
                    g_func.errParam(this, `请输入拍摄地点`);
                    return
                }
                return {
                    vid: this.mVideo.id,
                    stars: stars,
                    time: shootTime,
                    place: p.mPlace,
                    desc: p.mDescription,
                }
            }
        }
    }
</script>

<style scoped src="../css/order.css">
</style>