<template>
    <div>
        <div>
            <Button :type="mBtnStat[1]" class="btn" @click="users(1)">编导</Button>
            <Button :type="mBtnStat[3]" class="btn" @click="users(3)">制作人</Button>
            <Button :type="mBtnStat[4]" class="btn" @click="users(4)">录音师</Button>
            <Button :type="mBtnStat[5]" class="btn" @click="users(5)">混音师</Button>
            <Button :type="mBtnStat[2]" class="btn" @click="users(2)">项目经理</Button>
            <Button :type="mBtnStat[6]" class="btn" @click="users(6)">商务</Button>
            <Button :type="mBtnStat[8]" class="btn" @click="users(8)">达人</Button>
        </div>
        <div style="margin-top: 20px;">
            <!--            <div style="display: flex; flex-direction: row; align-items: baseline; margin-top: 20px">-->
            <!--                <DatePicker style="width: 400px" type="datetimerange" placeholder="选择查询时间" format="yyyy年M月d日 HH:mm"-->
            <!--                            v-model="mTime"-->
            <!--                            :editable="false"/>-->
            <!--                <Button type="primary" style="margin-left: 20px" @click="search">查询</Button>-->
            <!--            </div>-->
            <div style="margin-top: 20px">
                <Table border :loading=mIsLoading :columns="mTbColumn" :data="mTbData">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
    import {g_func} from "../source";

    export default {
        name: "PlatformDataUser",
        data() {
            return {
                mIsLoading: false,
                mTime: [(g_func.getDatetime(new Date((new Date()).getTime() - 1000 * 3600 * 24 * 7))), g_func.getDatetime(new Date())],
                mTbColumn: [
                    {
                        title: '序号',
                        key: 'index',
                        align: 'center',
                    },
                    {
                        title: '姓名',
                        key: 'name',
                        align: 'center',
                    },
                    {
                        title: '进行中短视频',
                        key: 'videoDoing',
                        align: 'center',
                    },
                    {
                        title: '进行中项目',
                        key: 'projectDoing',
                        align: 'center',
                    },
                    {
                        title: '最近7天上线视频',
                        key: 'onlineWeek',
                        align: 'center',
                    },
                    {
                        title: '本月上线视频',
                        key: 'onlineMonth',
                        align: 'center',
                    },
                ],
                mTbData: [],
                mBtnStat: ['primary']
            }
        },
        methods: {
            users(n) {
                if (n > 8) {
                    return;
                }
                this.mBtnStat = new Array(9);
                this.mBtnStat[n] = "primary";
                this.search(n)
            },
            search(careerId) {
                this.mIsLoading = true;
                this.mTbData = [];
                g_func.getDataUser(careerId, res => {
                    this.mIsLoading = false;
                    if (!g_func.checkResCode(this, res, `查询人员数据失败`)) {
                        return 0;
                    }
                    res.msg.forEach((v, k) => {
                        v.index = k + 1;
                        if (careerId === 2 || careerId === 6) {
                            v.videoDoing = v.onlineWeek = v.onlineMonth = '-';
                        }
                        this.mTbData.push(v);
                    })
                })
            }
        },
        created() {
            this.users(1);
        }
    }
</script>

<style scoped>
    .btn {
        margin: 5px;
        width: 7vw;
        min-width: 60px;
    }
</style>