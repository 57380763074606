<template>
    <div>
        <page-header
                :title-txt="'申请立项'"
        >
        </page-header>
        <div style="display: flex; flex-direction: row; justify-content: center">
            <div style="width: 900px; text-align: left">
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">项目名称：</span>
                    <Input class="add-video-input-select" v-model="mTitle"/>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">项目类型：</span>
                    <Select class="add-video-input-select" placeholder="请选择" v-model="mType">
                        <Option v-for="(item, i) in mTypeList" :value="i+1" :key="i">{{item}}
                        </Option>
                    </Select>
                </div>
                <div class="add-video-input item-space">
                    <span class="add-video-input-title title">品牌方：</span>
                    <Select class="add-video-input-select" placeholder="请选择" v-model="mCompany">
                        <Option v-for="(item, i) in mCompanyList" :value="item" :key="i">{{item}}
                        </Option>
                    </Select>
                </div>

                <div class="add-video-input item-space" v-if="mType === 2">
                    <span class="add-video-input-title title">推广歌曲：</span>
                    <Input class="add-video-input-select" v-model="mMusic"/>
                </div>

                <div class="add-video-input item-space desc">
                    <span class="add-video-input-title title">项目简述：</span>
                    <label class="add-video-input-select">
                        <Input type="textarea" :rows="10" v-model="mDescription"/>
                    </label>
                </div>

                <div class="add-video-input item-space" v-for="it in mProjectStars" :key="it.index">
                    <span class="add-video-input-title title">指定达人</span>
                    <Select class="add-video-input-select" placeholder="请选择" v-model="it.select">
                        <Option v-for="item in mStars" :value="item.id" :key="item.id">{{item.name}}
                        </Option>
                    </Select>
                    <div class="item-delete" @click="delProjectItem(it.index)">
                        <Icon type="ios-remove-circle-outline" size="20" color="red"/>
                    </div>
                </div>
                <div class="item-space">
                    <Button type="primary" @click="addStar">添加达人</Button>
                </div>

                <div class="add-video-input item-space">
                    <span class="title2 add-video-input-title">脚本提交时间：</span>
                    <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                placeholder="选择预约时间"
                                v-model="mScriptTime"/>
                </div>
                <div v-if="mType === 1">
                    <div class="add-video-input item-space">
                        <span class="title2 add-video-input-title">物料提交时间：</span>
                        <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                    placeholder="选择预约时间"
                                    v-model="mMaterialTime"/>
                    </div>
                </div>

                <div v-if="mType === 2">
                    <div class="add-video-input item-space">
                        <span class="title2 add-video-input-title">编曲提交时间：</span>
                        <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                    placeholder="选择预约时间"
                                    v-model="mMusicTime"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="title2 add-video-input-title">录音提交时间：</span>
                        <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                    placeholder="选择预约时间"
                                    v-model="mRecordTime"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="title2 add-video-input-title">混音提交时间：</span>
                        <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                    placeholder="选择预约时间"
                                    v-model="mMixTime"/>
                    </div>
                    <div class="add-video-input item-space">
                        <span class="title2 add-video-input-title">拍摄提交时间：</span>
                        <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                    placeholder="选择预约时间"
                                    v-model="mShootTime"/>
                    </div>
                </div>
                <div class="add-video-input item-space">
                    <span class="title2 add-video-input-title">上线时间：</span>
                    <DatePicker class="add-video-input-select" type="datetime" format="yyyy年M月d日 HH:mm"
                                placeholder="选择预约时间"
                                v-model="mOnlineTime"/>
                </div>

                <div class="item-space">
                    <Button :loading="mIsLoading" size="large" type="primary" style="width:100%;margin-top: 20px"
                            @click="submit">申请立项
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";

    export default {
        name: "AddProject",
        components: {PageHeader},
        data() {
            return {
                mTitle: '',
                mType: 1,
                mIsLoading: false,
                mTypeList: ['广告向', '歌曲推广向'],
                mCompany: '',
                mCompanyList: ['品牌方1', '品牌方2'],
                mMusic: '',
                mDescription: '',
                mScriptTime: '',
                mMaterialTime: '',
                mOnlineTime: '',
                mMusicTime: '',
                mRecordTime: '',
                mMixTime: '',
                mShootTime: '',
                mStars: [],
                mProjectStars: [],
            }
        },
        created() {
            g_func.getStars((res) => {
                if (!g_func.checkResCode(this, res, `获取达人失败`)) {
                    return 0;
                }
                this.mStars = [];
                res.msg.stars.forEach((v) => {
                    this.mStars.push({
                        id: v.s.id,
                        name: v.s.n,
                    });
                });
            });
            this.mProjectStars.push({
                index: this.mProjectStars.length,
                select: 0,
            });
        },
        methods: {
            submit() {
                if (!this.mTitle || !this.mType) {
                    g_func.errParam(this, '项目名称和项目类型不能为空');
                    return
                }
                let stars = [];
                this.mProjectStars.forEach((v) => {
                    if (v.select) {
                        stars.push(v.select)
                    }
                });
                if (stars.length !== (new Set(stars)).size) {
                    g_func.errParam(this, '选择达人有重复');
                    return;
                }
                if (!this.mScriptTime) {
                    g_func.errParam(this, '请输入脚本提交时间')
                    return;
                }
                if (this.mType === 2) {
                    if (!this.mMusicTime) {
                        g_func.errParam(this, '请输入编曲提交时间')
                        return;
                    }
                    if (!this.mRecordTime) {
                        g_func.errParam(this, '请输入录音提交时间')
                        return;
                    }
                    if (!this.mMixTime) {
                        g_func.errParam(this, '请输入混音提交时间')
                        return;
                    }
                    if (!this.mShootTime) {
                        g_func.errParam(this, '请输入拍摄提交时间')
                        return;
                    }
                } else {
                    if (!this.mMaterialTime) {
                        g_func.errParam(this, '请输入物料提交时间')
                        return;
                    }
                }
                if (!this.mOnlineTime) {
                    g_func.errParam(this, '请输入上线时间')
                    return;
                }
                if (!this.mCompany) {
                    g_func.errParam(this, '请选择品牌方')
                    return;
                }
                let b = {
                    'name': this.mTitle,
                    'type': this.mType,
                    'company': this.mCompany,
                    'musicName': this.mMusic,
                    'stars': stars,
                    'desc': this.mDescription,
                    'scriptT': this.mScriptTime,
                    'materialT': this.mMaterialTime,
                    'onlineT': this.mOnlineTime,
                    'musicT': this.mMusicTime,
                    'recordT': this.mRecordTime,
                    'mixT': this.mMixTime,
                    'shootT': this.mShootTime,
                };
                g_func.addProject(b, (res) => {
                    if (!g_func.checkResCode(this, res, `申请立项`)) {
                        return 0;
                    }
                    g_func.info(this, '添加项目成功', '');
                    this.$router.push({name:'Projects'});
                });
            },
            addStar() {
                this.mProjectStars.push({
                    index: this.mProjectStars.length,
                    select: 0,
                })
            },
            delProjectItem(index) {
                this.mProjectStars = this.mProjectStars.filter((v) => {
                    return v.index !== index;
                });
            }
        }
    }
</script>

<style scoped src="../css/order.css">
</style>
