<template>
    <div>
        <page-header
                :title-txt="txt() + '员工'"
        >
        </page-header>
        <div style="display: flex;flex-direction: row;justify-content: center">
            <div class="add-user-border">
                <label class="add-user-search-input">
                    <Input placeholder="姓名" v-model="mName"/>
                </label>
                <label class="add-user-search-input">
                    <Select style="text-align: left" placeholder="角色" v-model="mCareerId" :disabled="mIsEdit">
                        <Option v-for="item in mCareerList" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                </label>
                <label class="add-user-search-input">
                    <Input placeholder="电话" v-model="mPhone" :disabled="mIsEdit"/>
                </label>
                <label class="add-user-l" style="margin-top: 14px">
                    <Checkbox v-model="mIsManager">项目经理</Checkbox>
                    <Checkbox v-model="mDr1">查看业务数据</Checkbox>
                </label>
                <label class="add-user-l">
                    <Checkbox v-model="mIsMusicConfirm">审核编曲需求</Checkbox>
                    <Checkbox v-model="mDr2">查看人员数据</Checkbox>
                </label>
                <label class="add-user-l">
                    <Checkbox v-model="mIsAdmin">管理员</Checkbox>
                    <Checkbox v-model="mIsIdea">上传到创意库</Checkbox>
                </label>
                <label class="add-user-l">
                    <div></div>
                    <Checkbox v-model="mIsStore">上传到资源库</Checkbox>
                </label>
                <Button type="primary" :loading="mLoading" @click="addUser" class="add-user-search-input"
                        style="margin-top: 20px">{{txt()}}
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "./PageHeader";
    import {g_func} from "../source";

    export default {
        name: "AddUser",
        components: {PageHeader},
        props: ['id'],
        data() {
            return {
                mName: this.n,
                mCareerId: 0,
                mPhone: '',
                mIsEdit: false,
                mIsManager: false,
                mIsMusicConfirm: false,
                mIsAdmin: false,
                mIsIdea: false,
                mIsStore: false,
                mDr1: false,
                mDr2: false,
                mDr3: false,
                mLoading: false,
                mCareerList: [],
            }
        },
        created() {
            if (this.id) {
                g_func.getUser(this.id, res => {
                    this.mName = res.msg.name;
                    this.mPhone = res.msg.phone;
                    this.mCareerId = res.msg.careerId;
                    this.mIsEdit = !!this.id;
                    this.mIsManager = res.msg.pm === 1;
                    this.mIsMusicConfirm = res.msg.mc === 1;
                    this.mIsAdmin = res.msg.admin === 1;
                    this.mDr1 = !!(res.msg.dr & 0x1);
                    this.mDr2 = !!(res.msg.dr >> 1 & 0x1);
                    this.mDr3 = !!(res.msg.dr >> 2 & 0x1);
                });
                g_func.getUserRights(this.id, res => {
                    this.mIsIdea = res.msg.indexOf('24') !== -1;
                    this.mIsStore = res.msg.indexOf('25') !== -1
                })
            }
            g_func.getCareerList((res) => {
                if (!g_func.checkResCode(this, res, '获取职业列表错误')) {
                    return;
                }
                this.mCareerList = res.msg.list.filter((n) => {
                    return n.id !== 8
                });
            });
        },
        methods: {
            addUser() {
                let arg = {
                    id: this.id ? this.id : 0,
                    fullName: this.mName,
                    careerId: this.mCareerId,
                    phone: this.mPhone,
                    projectManager: this.mIsManager ? 1 : 0,
                    musicConfirm: this.mIsMusicConfirm ? 1 : 0,
                    admin: this.mIsAdmin ? 1 : 0,
                    idea: this.mIsIdea,
                    store: this.mIsStore,
                };

                if (!arg.fullName || !arg.careerId || !arg.phone) {
                    g_func.errParam(this, '姓名、职业、电话不能为空');
                    return 0
                }
                let dr = 0;
                if (this.mDr1) {
                    dr += 1
                }
                if (this.mDr2) {
                    dr += 2
                }
                if (this.mDr3) {
                    dr += 4
                }
                this.mLoading = true;
                let finish = (res) => {
                    this.mLoading = false;
                    if (!g_func.checkResCode(this, res, '操作失败')) {
                        return 0
                    }
                    g_func.doOk(this);
                    this.$router.push({name: 'Users', params: {cid: this.mCareerId}});
                };
                if (arg.id === 0) {
                    g_func.addUser(arg.fullName, arg.phone, arg.careerId, arg.projectManager, arg.musicConfirm, arg.admin, dr, arg.idea, arg.store, (res) => {
                        finish(res);
                    })
                } else {
                    g_func.editUser(arg.id, arg.fullName, arg.projectManager, arg.musicConfirm, arg.admin, dr, arg.idea, arg.store, (res) => {
                        finish(res);
                    })
                }
            },
            txt() {
                return this.mIsEdit ? '编辑' : '添加'
            }
        }
    }
</script>

<style scoped>

    .add-user-border {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .add-user-search-input {
        width: 100%;
        margin-top: 10px;
    }

    .add-user-l {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
</style>