<template>
    <div>
        <div class="header">
            <span class="title">
                {{title}}
            </span>
            <div style="display: flex; position: relative; top: 20px">
                <div v-if="btn1Text" class="header-btn">
                    <Button type="info" @click="btn1Click">{{btn1Text}}</Button>
                </div>
                <div v-if="btn2Text" class="header-btn">
                    <Button type="info" @click="btn2Click">{{btn2Text}}</Button>
                </div>
            </div>
        </div>
        <Divider>
        </Divider>
    </div>
</template>

<script>
    export default {
        name: "PageHeader",
        props: ["btn1Txt", "btn2Txt", "titleTxt"],
        data() {
            return {
                title: this.titleTxt,
                btn1Text: this.btn1Txt,
                btn2Text: this.btn2Txt,
            }
        },
        methods: {
            btn1Click() {
                this.$emit("btn1Click");
            },
            btn2Click() {
                this.$emit("btn2Click");
            },
        },
        watch : {
            titleTxt() {
                this.title = this.titleTxt;
            }
        }
    }
</script>

<style scoped>
    .title {
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        color: black;
        font-size: 25px;
        font-weight: 700;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-btn {
        padding: 0 5px 0 5px;
    }

</style>